import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { ConvexReactClient } from 'convex/react';
import { SessionProvider } from 'convex-helpers/react/sessions';
import { useLocalStorage } from 'usehooks-ts';
import { NextUIProvider } from '@nextui-org/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as Sentry from '@sentry/react';
import { ConvexAuthProvider } from '@convex-dev/auth/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_DOMAIN],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const convex = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL as string);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <NextUIProvider>
      <ConvexAuthProvider client={convex}>
        <SessionProvider storageKey="solitaire" useStorage={useLocalStorage}>
          <App />
        </SessionProvider>
      </ConvexAuthProvider>
    </NextUIProvider>
  </React.StrictMode>,
);
