import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

export interface IMlLineScoreProps {
  icon: React.ReactNode;
  text: string;
  score: number;
  largeScore?: boolean;
  play?: boolean;
}

export const lineScoreDuration = 0.5;

export default function MlLineScore({
  largeScore,
  score,
  icon,
  text,
  play,
}: IMlLineScoreProps) {
  const [number, setNumber] = useState(0);
  const numberRef = useRef(null);
  const iconRef = useRef(null);

  useGSAP(() => {
    if (!play) {
      return;
    }
    // score number animation
    gsap.to(numberRef.current, {
      duration: lineScoreDuration, // 动画持续时间
      innerText: score, // 目标值
      roundProps: 'innerText', // 保证值是整数
      ease: 'power1.inOut', // 缓动函数
      onUpdate: function () {
        setNumber(Math.round(this.targets()[0].innerText));
      },
    });

    // icon animation
    gsap.fromTo(
      iconRef.current,
      { scale: 1.5 }, // 初始大小
      { scale: 1, duration: lineScoreDuration, ease: 'power1.inOut' }, // 目标大小
    );
  }, [play]);

  return (
    <div className={clsx('flex justify-between px-5')}>
      <span className="flex items-center gap-4">
        <span ref={iconRef}>{icon}</span>
        <span
          className={clsx({
            'text-[#F3D96F]': largeScore,
            'text-xl': largeScore,
          })}
        >
          {text}
        </span>
      </span>
      <span
        className={clsx('text-[#6EE9D4] text-lg', {
          'text-xl': largeScore,
          'text-[#F3D96F]': largeScore,
        })}
        ref={numberRef}
      >
        {number}
      </span>
    </div>
  );
}
