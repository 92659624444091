import { useEffect } from 'react';
import { GAME_HEIGHT, GAME_WIDTH } from '@/game/utils/constants';
import { PreloadScene } from '@/game/scenes/preloadScene';
import { CombatScene } from '@/game/scenes/combatScene';

export function useInitPhaserGame() {
  useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      width: GAME_WIDTH,
      height: GAME_HEIGHT,
      scale: {
        parent: 'game',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      transparent: true,
      type: Phaser.AUTO,
      scene: [PreloadScene, CombatScene],
      title: 'Solitaire4Win',
      banner: {
        hidePhaser: true,
        text: '#ffffff',
        background: [],
      },
      url: '',
    };

    const game = new Phaser.Game(config);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.__PHASER_GAME__ = game;
    // }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalThis.__PHASER_GAME__.destroy(true);
    };
  }, []);
}
