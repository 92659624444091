export enum SuitEnum {
  Spades = 's',
  Hearts = 'h',
  Diamonds = 'd',
  Clubs = 'c',
}

export enum AreaEnum {
  DeckLeft = 'deck_left',
  DeckRight = 'deck_right',
  Foundation = 'foundation',
  Tableau = 'tableau',
}

export enum ActionEnum {
  T_TO_T = 'TABLEAU_TO_TABLEAU',
  F_TO_T = 'FOUNDATION_TO_TABLEAU',
  T_TO_F = 'T_TO_F',
  DECK_RIGHT_TO_LEFT = 'DECK_RIGHT_TO_LEFT',
  DECK_LEFT_TO_TABLEAU = 'DECK_LEFT_TO_TABLEAU',
  DECK_LEFT_TO_FOUNDATION = 'DECK_LEFT_CLICK_OR_DRAG_TO_FOUNDATION',
  DECK_BASE_CLICK = 'DECK_BASE_CLICK',
  UNDO = 'UNDO',
}
