export default function AtMedalTwoIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Flat">
          {' '}
          <g id="Color">
            {' '}
            <polygon
              fill="#212529"
              points="45 17 32 25 19 17 19 3 45 3 45 17"
            ></polygon>{' '}
            <polygon
              fill="#dd051d"
              points="40 3 40 20.08 32 25 24 20.08 24 3 40 3"
            ></polygon>{' '}
            <path
              d="M32,25l6.49-4a21.36,21.36,0,0,0-13,0Z"
              fill="#a60416"
            ></path>{' '}
            <circle cx="32" cy="41.5" fill="#fccd1d" r="19.5"></circle>{' '}
            <circle cx="32" cy="41.5" fill="#f9a215" r="14.5"></circle>{' '}
            <path
              d="M33.88,33.57a6.49,6.49,0,0,0-5.81,1.23,6.41,6.41,0,0,0-2.21,4.89H30c0-2.24,3.37-2.38,4-1,1,2.1-8,7-8,7v4H38v-4H34a7.07,7.07,0,0,0,4-7.54A6.16,6.16,0,0,0,33.88,33.57Z"
              fill="#fccd1d"
            ></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
