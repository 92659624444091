import { useSessionId } from 'convex-helpers/react/sessions';
import { userStore } from './zustand/userStore';
import PgGame from './components/pages/PgGame';
import PgApp from './components/pages/PgApp';
import { uiManagementStore } from './zustand/uiManagementStore';
import OrLoadingModal from './components/organisms/modals/OrLoadingModal.tsx';
import { useEffect } from 'react';
import { interactionStore } from './zustand/interactionStore';
import OrResultStatusModal from './components/organisms/modals/OrResultStatusModal';
import MlCollectRewardsAnimationLayer from './components/molecules/MlCollectRewardsAnimationLayer';

function App() {
  const [sessionId] = useSessionId();

  const setSessionId = userStore((state) => state.setSessionId);

  const {
    hidePhaserGame,
    showResultStatusModal,
    selectedTid,
    showCollectAnimationLayer,
  } = uiManagementStore((state) => state);

  useEffect(() => {
    if (hidePhaserGame) {
      interactionStore.setState({ canInteract: true });
    }
  }, [hidePhaserGame]);

  useEffect(() => {
    setSessionId(sessionId ? sessionId : null);
  }, [sessionId, setSessionId]);

  return (
    <div className="h-svh bg-[url('/assets/solitaire/images/bgs/3.png')] bg-cover">
      {hidePhaserGame ? <PgApp /> : <PgGame />}
      <OrResultStatusModal isOpen={showResultStatusModal} tid={selectedTid} />
      {showCollectAnimationLayer && <MlCollectRewardsAnimationLayer />}
      <OrLoadingModal />
    </div>
  );
}

export default App;
