import Slider from 'react-slick';
import TmShop from '../templates/TmShop';
import TmHome from '../templates/TmHome';
import TmResults from '../templates/TmResults';
import OrNav from '../organisms/OrNav';
import { useEffect, useRef, useState } from 'react';
import { getUnfinishedGame } from '@/services/game';
import { uiManagementStore } from '@/zustand/uiManagementStore';
import { EventBus } from '@/utils/eventBus';
import { START_GAME } from '@/game/utils/eventNames';
import clsx from 'clsx';
import OrTopBar from '../organisms/OrTopBar';
import { cleanGameStore } from '@/zustand/gameStore';
import { userStore } from '@/zustand/userStore';

function TmWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-full text-white p-4 max-w-3xl mx-auto">{children}</div>
  );
}

export default function PgApp() {
  const sessionId = userStore((state) => state.sessionId);
  const { isLoading } = uiManagementStore((state) => state);
  const sliderRef = useRef<Slider>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isDesktop = window.innerWidth > 1024;

  const [currentSlide, setCurrentSlide] = useState(1);
  const speed = 500;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 1,
    adaptiveHeight: true,
    useTransform: true,
    useCss: true,
    touchThreshold: isDesktop ? 100 : 1000,
    pauseOnHover: false,
    // fade: true,

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    beforeChange: (_current, next) =>
      setTimeout(() => setCurrentSlide(next), speed),
  };

  const preventHorizontalScroll = (element: HTMLDivElement) => {
    let startX = 0;
    let startY = 0;

    const onTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };

    const onTouchMove = (e: TouchEvent) => {
      const moveX = e.touches[0].clientX - startX;
      const moveY = e.touches[0].clientY - startY;

      const touchStartNearEdges =
        startX < 20 || startX > window.innerWidth - 20;

      if (Math.abs(moveX) > Math.abs(moveY) || touchStartNearEdges) {
        e.preventDefault();
      }
    };

    element.addEventListener('touchstart', onTouchStart, { passive: false });
    element.addEventListener('touchmove', onTouchMove, { passive: false });

    return () => {
      element.removeEventListener('touchstart', onTouchStart);
      element.removeEventListener('touchmove', onTouchMove);
    };
  };

  useEffect(() => {
    (async () => {
      try {
        if (!sessionId) return;
        uiManagementStore.setState({ isLoading: true });
        const game = await getUnfinishedGame();
        if (game) {
          EventBus.emit(START_GAME);
          uiManagementStore.setState({
            hidePhaserGame: false,
            isLoading: true,
          });
        } else {
          // clean game store
          cleanGameStore();
          uiManagementStore.setState({ isLoading: false });
        }
      } catch (error) {
        console.error('Error getting unfinished game:', error);
        uiManagementStore.setState({ isLoading: false });
      }
    })().catch(console.error);
  }, [sessionId]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const cleanup = preventHorizontalScroll(wrapperRef.current);
    return cleanup;
  }, []);

  return (
    <>
      <div
        ref={wrapperRef}
        className={clsx('flex flex-col h-full', {
          'opacity-0': isLoading,
        })}
      >
        <div className="bg-[#214742]">
          <div className="w-full max-w-3xl mx-auto flex justify-center text-white px-2 py-2">
            <OrTopBar />
          </div>
        </div>

        <Slider ref={sliderRef} {...settings} className="h-full w-full ">
          <TmWrapper>
            <TmShop />
          </TmWrapper>
          <TmWrapper>
            <TmHome />
          </TmWrapper>
          <TmWrapper>
            <TmResults sliderRef={sliderRef} />
          </TmWrapper>
        </Slider>

        <OrNav
          sliderRef={sliderRef}
          currentIndex={currentSlide}
          setCurrentIndex={setCurrentSlide}
        />
      </div>
    </>
  );
}
