import OrShopItemCard from '../organisms/OrShopItemCard';

const testData = [1, 2, 3, 4, 5, 6];

export default function TmShop() {
  return (
    <div className="text-white flex flex-wrap justify-between gap-0">
      {testData.map((item) => (
        <div key={item} className="w-[31%] m-1">
          <OrShopItemCard />
        </div>
      ))}
    </div>
  );
}
