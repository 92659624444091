import { useQuery } from 'convex/react';
import MlBanner from '../molecules/MlBanner';
import MlResultCard from '../molecules/MlResultCard';
import { api } from '@c/_generated/api';
import { userStore } from '@/zustand/userStore';
import { useEffect, useMemo } from 'react';
import { tournamentResultsStore } from '@/zustand/tournamentResultsStore';
import { TournamentResultStatusEnum } from '../../../enums/tournament';
import AtWheelLottie from '../atoms/AtWheelLottie';
import { Button } from '@nextui-org/react';
import Slider from 'react-slick';

interface TmResultsProps {
  sliderRef: React.RefObject<Slider>;
}

export default function TmResults({ sliderRef }: TmResultsProps) {
  const sessionId = userStore((state) => state.sessionId);

  const allResults = useQuery(api.tournament.functions.queryTournamentResults, {
    sessionId: sessionId || '',
  });

  const { ongoingTournament, nonCollectedTournaments, collectedTournaments } =
    tournamentResultsStore((state) => state);

  const showCurrent = useMemo(() => {
    const showOngoing = ongoingTournament && ongoingTournament.length > 0;
    const showNonCollected =
      nonCollectedTournaments && nonCollectedTournaments.length > 0;

    return showOngoing || showNonCollected;
  }, [nonCollectedTournaments, ongoingTournament]);

  const showCompleted = useMemo(() => {
    return collectedTournaments && collectedTournaments.length > 0;
  }, [collectedTournaments]);

  useEffect(() => {
    tournamentResultsStore.setState({
      ongoingTournament: allResults?.ongoingTournament,
      nonCollectedTournaments: allResults?.nonCollectedTournaments,
      collectedTournaments: allResults?.collectedTournaments,
    });
  }, [
    allResults?.collectedTournaments,
    allResults?.nonCollectedTournaments,
    allResults?.ongoingTournament,
  ]);

  if (allResults !== undefined) {
    const isEmpty =
      allResults?.ongoingTournament?.length === 0 &&
      allResults?.nonCollectedTournaments?.length === 0 &&
      allResults?.collectedTournaments?.length === 0;

    if (isEmpty) {
      return (
        <div className="flex flex-col justify-center items-center">
          <AtWheelLottie />
          <Button
            onPress={() => {
              sliderRef.current?.slickGoTo(1);
            }}
            radius="full"
            className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg font-bold text-lg"
          >
            Join a Tournament
          </Button>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col gap-2.5">
      {showCurrent && (
        <>
          {/* banner */}
          <div className="w-4/5 sm:w-3/5 mx-auto">
            <MlBanner text="Current Tournaments" />
          </div>
          {/* ongoing  */}
          {ongoingTournament?.map((tr) => (
            <div className="sm:px-10" key={Math.random()}>
              <MlResultCard
                data={tr}
                status={TournamentResultStatusEnum.ONGOING}
              />
            </div>
          ))}
          {/* non collected */}
          {nonCollectedTournaments?.map((tr) => (
            <div className="sm:px-10" key={Math.random()}>
              <MlResultCard
                data={tr}
                status={TournamentResultStatusEnum.NON_COLLECTED}
              />
            </div>
          ))}
        </>
      )}
      {showCompleted && (
        <>
          {/* banner */}
          <div className="w-4/5 sm:w-3/5 mx-auto" key={Math.random()}>
            <MlBanner text="Completed Tournaments" />
          </div>
          {/* completed */}
          {collectedTournaments?.map((tr) => (
            <div className="sm:px-10" key={Math.random()}>
              <MlResultCard
                data={tr}
                status={TournamentResultStatusEnum.COLLECTED}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
