import AtDiamondIcon from '@/components/atoms/icons/AtDiamondIcon';
import {
  closeShowAnimationLayer,
  uiManagementStore,
} from '@/zustand/uiManagementStore';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CollectTypeEnum } from '../../../../enums/tournament';
import AtDollarIcon from '@/components/atoms/icons/AtDollarIcon';

const coins = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 1 },
  { id: 2 },
  { id: 3 },
];

export default function MlCollectRewardsAnimationLayer() {
  const bodyRectRef = useRef<HTMLDivElement>(null);

  const [centerPosition, setCenterPosition] = useState({ x: 0, y: 0 });

  const { diamondPosition, dollarPosition, collectType } = uiManagementStore(
    (state) => state,
  );

  const playAni = useMemo(() => {
    return (
      diamondPosition && dollarPosition && centerPosition.x && centerPosition.y
    );
  }, [centerPosition.x, centerPosition.y, diamondPosition, dollarPosition]);

  const targetPosition = useMemo(() => {
    if (collectType === CollectTypeEnum.CRYSTALS) {
      return diamondPosition;
    }
    if (collectType === CollectTypeEnum.CASH) {
      return dollarPosition;
    }
    return null;
  }, [collectType, diamondPosition, dollarPosition]);

  useEffect(() => {
    if (!bodyRectRef.current) return;
    const rect = bodyRectRef.current.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    setCenterPosition({ x: centerX, y: centerY });
  }, [diamondPosition, dollarPosition]);

  return (
    <div className="fixed w-screen h-svh top-0 bg-black bg-opacity-70">
      <div ref={bodyRectRef} className="relative w-full h-full">
        {playAni &&
          coins.map((coin, i) => (
            <motion.span
              key={coin.id + Math.random()}
              className="absolute w-5 z-10"
              initial={{
                opacity: 0,
                x: centerPosition.x,
                y: centerPosition.y,
              }}
              animate={{
                opacity: 1,
                y: targetPosition?.y,
                x: targetPosition?.x,
              }}
              transition={{
                y: { duration: 0.8, delay: i / 15 },
                x: { duration: 0.8, delay: i / 15 },
              }}
              onAnimationComplete={() => {
                if (i === coins.length - 1) {
                  closeShowAnimationLayer();
                }
              }}
            >
              {collectType === CollectTypeEnum.CASH && <AtDollarIcon />}
              {collectType === CollectTypeEnum.CRYSTALS && <AtDiamondIcon />}
            </motion.span>
          ))}
      </div>
    </div>
  );
}
