export default function AtGroupIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 509.421 509.421"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g>
          {' '}
          <path
            style={{ fill: '#9BC9FF' }}
            d="M409.421,251.348V123.073c-35.366,0-64.139,28.772-64.139,64.138S374.055,251.348,409.421,251.348z"
          ></path>{' '}
          <path
            style={{ fill: '#57A4FF' }}
            d="M473.56,187.211c0-35.366-28.772-64.138-64.139-64.138v128.275 C444.788,251.348,473.56,222.576,473.56,187.211z"
          ></path>{' '}
          <path
            style={{ fill: '#9BC9FF' }}
            d="M509.421,366.348c0-55.14-44.859-100-100-100c-28.619,0-55.206,12.071-73.879,32.602 c26.098,21.11,43.814,52.191,47.288,87.398h126.591V366.348z"
          ></path>{' '}
          <path
            style={{ fill: '#9BC9FF' }}
            d="M173.879,298.95c-18.673-20.53-45.26-32.602-73.879-32.602c-55.14,0-100,44.86-100,100v20h126.59 C130.065,351.141,147.781,320.06,173.879,298.95z"
          ></path>{' '}
          <path
            style={{ fill: '#9BC9FF' }}
            d="M100,251.348V123.073c-35.366,0-64.138,28.772-64.138,64.138S64.634,251.348,100,251.348z"
          ></path>{' '}
          <path
            style={{ fill: '#57A4FF' }}
            d="M164.138,187.211c0-35.366-28.772-64.138-64.138-64.138v128.275 C135.366,251.348,164.138,222.576,164.138,187.211z"
          ></path>{' '}
          <path
            style={{ fill: '#003F8A' }}
            d="M335.542,298.95c-22.114-17.888-50.242-28.619-80.832-28.619v148.76h128.75v-20 c0-4.3-0.216-8.55-0.63-12.742C379.356,351.141,361.64,320.06,335.542,298.95z"
          ></path>{' '}
          <path
            style={{ fill: '#003F8A' }}
            d="M334.71,170.331c0-44.11-35.89-80-80-80v160C298.82,250.331,334.71,214.451,334.71,170.331z"
          ></path>{' '}
          <path
            style={{ fill: '#005ECE' }}
            d="M173.879,298.95c-26.098,21.11-43.814,52.191-47.289,87.398c-0.414,4.193-0.63,8.443-0.63,12.742v20 h128.75v-148.76C224.121,270.331,195.993,281.062,173.879,298.95z"
          ></path>{' '}
          <path
            style={{ fill: '#005ECE' }}
            d="M254.71,250.331v-160c-44.11,0-80,35.89-80,80C174.71,214.451,210.6,250.331,254.71,250.331z"
          ></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
