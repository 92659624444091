export default function AtMedalThirdIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Flat">
          {' '}
          <g id="Color">
            {' '}
            <polygon
              fill="#212529"
              points="45 17 32 25 19 17 19 3 45 3 45 17"
            ></polygon>{' '}
            <polygon
              fill="#dd051d"
              points="40 3 40 20.08 32 25 24 20.08 24 3 40 3"
            ></polygon>{' '}
            <path
              d="M32,25l6.49-4a21.36,21.36,0,0,0-13,0Z"
              fill="#a60416"
            ></path>{' '}
            <circle cx="32" cy="41.5" fill="#fccd1d" r="19.5"></circle>{' '}
            <circle cx="32" cy="41.5" fill="#f9a215" r="14.5"></circle>{' '}
            <path
              d="M36.54,41.5A4.52,4.52,0,0,0,38.38,38c0-2.76-2.86-5-6.38-5s-6.37,2.24-6.37,5h3.92a2,2,0,0,1,3.9-.29c.17,1.23-.77,2.73-2,2.73v2.12c2.22,0,2.84,3.5.72,4.32A2,2,0,0,1,29.55,45H25.63c0,2.76,2.85,5,6.37,5s6.38-2.24,6.38-5A4.52,4.52,0,0,0,36.54,41.5Z"
              fill="#fccd1d"
            ></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
