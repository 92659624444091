import React from 'react';
import { useLottie } from 'lottie-react';
import animationData from '@/assets/lottie/pokerLoading.json';

export default function AtPokerLoadingLottie() {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View, animationItem } = useLottie(options);

  React.useEffect(() => {
    if (animationItem) {
      animationItem.setSpeed(2);
    }
  }, [animationItem]);

  return (
    <div className="w-full h-full pointer-events-none flex justify-center">
      <div className="max-w-3xl">{View}</div>
    </div>
  );
}
