export enum MoneyTypeEnum {
  CRYSTALS = 'crystals',
  CASH = 'cash',
  ADS = 'ads',
}

export enum TournamentStatusEnum {
  WAITING = 'waiting',
  PLAYING = 'playing',
  FINISHED = 'finished',
}

export enum TournamentTypesEnum {
  ROOKIE = 'rookie',
  GEMS_FACTORY = 'gems_factory',
  HIGH_STAKES = 'high_stakes',
  FREEROLL = 'freeroll',
}

export enum TournamentResultStatusEnum {
  COLLECTED = 'collected',
  NON_COLLECTED = 'non_collected',
  ONGOING = 'ongoing',
}

export enum CollectTypeEnum {
  CRYSTALS = 'crystals',
  CASH = 'cash',
}
