export default function AtGoalIcon({
  width = 20,
  height = 20,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 330 330"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="XMLID_19_">
          {' '}
          <circle
            id="XMLID_20_"
            fill="#0052B4"
            cx="160"
            cy="170"
            r="160"
          ></circle>{' '}
          <path
            id="XMLID_21_"
            fill="#006DF0"
            d="M160,10c88.365,0,160,71.634,160,160s-71.635,160-160,160"
          ></path>{' '}
          <circle
            id="XMLID_22_"
            fill="#FFDA44"
            cx="160"
            cy="170"
            r="120"
          ></circle>{' '}
          <circle
            id="XMLID_23_"
            fill="#0052B4"
            cx="160"
            cy="170"
            r="80"
          ></circle>{' '}
          <path
            id="XMLID_24_"
            // style="fill:#006DF0;"
            fill="#006DF0"
            d="M160,90c44.183,0,80,35.817,80,80s-35.817,80-80,80"
          ></path>{' '}
          <circle
            id="XMLID_25_"
            fill="#FFDA44"
            cx="160"
            cy="170"
            r="40"
          ></circle>{' '}
          <g id="XMLID_26_">
            {' '}
            <path
              id="XMLID_27_"
              fill="#FFFFFF"
              d="M241.611,67.175l-8.031,8.031l-28.62,28.62l-29.34,29.34l-22.397,22.398 c-5.857,5.857-5.857,15.355,0,21.213c5.857,5.858,15.355,5.858,21.214,0l22.398-22.397l29.34-29.339l28.619-28.62l8.032-8.031 L241.611,67.175z"
            ></path>{' '}
            <polygon
              id="XMLID_28_"
              fill="#FF5023"
              points="241.611,67.175 238.076,91.924 280.502,49.497 287.574,0 249.924,37.649 245.146,42.427 "
            ></polygon>{' '}
            <polygon
              id="XMLID_29_"
              fill="#FF9811"
              points="238.076,91.924 262.825,88.389 287.573,84.853 292.351,80.075 330,42.427 280.502,49.497 "
            ></polygon>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
