export default function AtDiamondIcon() {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <polygon
            style={{ fill: '#00D2FF' }}
            points="368.552,150.069 406.069,44.138 256,44.138 105.931,44.138 143.448,150.069 "
          ></polygon>
          <polygon
            style={{ fill: '#00D2FF' }}
            points="256,150.069 512,150.069 256,467.862 "
          ></polygon>{' '}
        </g>{' '}
        <g>
          <polygon
            style={{ fill: '#18BDF6' }}
            points="256,44.138 256,150.069 0,150.069 105.931,44.138 "
          ></polygon>{' '}
          <polygon
            style={{ fill: '#18BDF6' }}
            points="256,150.069 0,150.069 256,467.862 "
          ></polygon>{' '}
          <polygon
            style={{ fill: '#18BDF6' }}
            points="256,44.138 256,150.069 512,150.069 406.069,44.138 "
          ></polygon>{' '}
        </g>{' '}
        <polygon
          style={{ fill: '#00D2FF' }}
          points="256,44.138 143.473,150.069 256,150.069 368.527,150.069 "
        ></polygon>{' '}
        <polygon
          style={{ fill: '#28AFF0' }}
          points="143.448,150.069 256,467.862 368.552,150.069 "
        ></polygon>{' '}
        <polygon
          style={{ fill: '#59E2FF' }}
          points="406.069,44.138 368.552,150.069 512,150.069 "
        ></polygon>{' '}
        <polygon
          style={{ fill: '#28AFF0' }}
          points="105.931,44.138 143.448,150.069 0,150.069 "
        ></polygon>{' '}
        <g>
          {' '}
          <path
            style={{ fill: '#FFFFFF' }}
            d="M426.244,93.358c-15.236-3.474-27.231-15.468-30.705-30.706c-0.262-1.148-1.781-1.148-2.042,0 c-3.474,15.236-15.468,27.231-30.705,30.706c-1.148,0.262-1.148,1.781,0,2.042c15.236,3.474,27.231,15.467,30.705,30.705 c0.262,1.148,1.781,1.148,2.042,0c3.474-15.238,15.468-27.231,30.705-30.705C427.391,95.139,427.391,93.62,426.244,93.358z"
          ></path>{' '}
          <path
            style={{ fill: '#FFFFFF' }}
            d="M386.731,157.426c-11.789-3.053-21.07-12.335-24.124-24.124c-0.307-1.184-1.839-1.184-2.146,0 c-3.053,11.789-12.335,21.07-24.124,24.124c-1.184,0.307-1.184,1.839,0,2.146c11.789,3.053,21.07,12.334,24.124,24.124 c0.307,1.184,1.839,1.184,2.146,0c3.053-11.789,12.335-21.07,24.124-24.124C387.915,159.265,387.915,157.732,386.731,157.426z"
          ></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
