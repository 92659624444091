import { Button, Card, CardBody } from '@nextui-org/react';
import AtSpadeIcon from '../../atoms/icons/AtSpadeIcon';
import AtGroupIcon from '../../atoms/icons/AtGroupIcon';
import { EventBus } from '@/utils/eventBus';
import { START_GAME } from '@/game/utils/eventNames';
import { uiManagementStore } from '@/zustand/uiManagementStore';
import clsx from 'clsx';
import AtDollarIcon from '../../atoms/icons/AtDollarIcon';
import { MoneyTypeEnum } from '../../../../enums/tournament';
import AtDiamondIcon from '../../atoms/icons/AtDiamondIcon';
import { tournamentTypesStore } from '@/zustand/tournamentTypesStore';
import OrGameCardModal from '../../organisms/modals/OrGameCardModal.tsx';
import { useState } from 'react';
import { transferToK } from '@/utils/unitTransfer';
import styles from './styles.module.scss';

export interface IMlGameCardProps {
  index: number;
}

// const goldenText = '#FDDE60';
// const oceanText = '#5FC8F7';

export default function MlGameCard({ index }: IMlGameCardProps) {
  const [cardModalOpen, setCardModalOpen] = useState(false);

  const dataArr = tournamentTypesStore((state) => state.data);

  if (!dataArr) return null;

  const currentData = dataArr[index];

  if (!currentData) return null;

  const {
    title,
    size,
    moneyType,
    moneyAmount,
    rewardMoneyAmount,
    rewardMoneyType,
    enabled,
    animate,
  } = currentData;

  const handleStartGame = () => {
    EventBus.emit(START_GAME);
    tournamentTypesStore.setState({
      currentType: currentData.type,
    });
    uiManagementStore.setState({ hidePhaserGame: false, isLoading: true });
  };

  if (!enabled) return null;

  return (
    <>
      <Card className={clsx(animate && styles.boxWithGlow)}>
        <CardBody
          onClick={() => {
            setCardModalOpen(true);
          }}
          className="p-1 h-[95px] sm:h-[7.5rem] bg-[#FAEAD1] text-white hover:cursor-pointer"
        >
          <div className="flex justify-between h-full">
            <div className="flex h-full flex-1">
              {/* prize pool */}
              <div className="bg-[#6729A0] aspect-square flex flex-col justify-center items-center rounded-l-lg relative">
                <div className="absolute w-full">
                  <AtSpadeIcon />
                </div>
                <div className="absolute flex flex-col justify-center items-center gap-2">
                  {rewardMoneyType === MoneyTypeEnum.CASH ? (
                    <div
                      className={clsx(
                        'text-3xl sm:text-4xl font-semibold text-[#FDDE60]',
                      )}
                    >
                      {`$${rewardMoneyAmount[0]}`}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center gap">
                      <div className="w-6 sm:w-10 flex justify-center items-center">
                        <AtDiamondIcon />
                      </div>
                      <div className="text-3xl sm:text-4xl font-semibold flex justify-center items-center">
                        {rewardMoneyAmount[0]}
                      </div>
                    </div>
                  )}
                  <div className="sm:text-xl font-semibold">Prize Pool</div>
                </div>
              </div>
              {/* middle info */}
              <div className="flex flex-col pl-1 sm:pl-8 sm:gap-1 justify-between">
                <div className="text-[#8F5F28] text-lg sm:text-2xl font-bold">
                  {title}
                </div>
                <div className="w-7 sm:w-10">
                  <AtGroupIcon />
                </div>
                <div className="bg-[#D6C393] rounded-full px-2 inline-flex self-start">
                  {`${size} Players`}
                </div>
              </div>
            </div>
            {/* entry fee */}
            <div className="aspect-square flex flex-col justify-between items-center rounded-r-lg bg-[#F1DEAF] p-1 sm:gap-1 sm:p-2">
              <div className="text-[#8F5F28] text-sm sm:text-xl font-semibold">
                Entry Fee
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="w-5 sm:w-8 flex justify-center items-center">
                  {moneyType === MoneyTypeEnum.CASH ? (
                    <AtDollarIcon />
                  ) : (
                    <AtDiamondIcon />
                  )}
                </div>
                <div className="font-semibold text-stroke-1 sm:text-xl">
                  {moneyType === MoneyTypeEnum.CASH
                    ? `$${moneyAmount}`
                    : transferToK(moneyAmount)}
                </div>
              </div>
              <Button
                color={moneyType === MoneyTypeEnum.CASH ? 'success' : 'warning'}
                className="font-bold !outline-none !focus:outline-none text-white w-full"
                onClick={handleStartGame}
                size="sm"
              >
                Play
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Modal */}
      <OrGameCardModal
        isOpen={cardModalOpen}
        modalToggle={setCardModalOpen}
        data={currentData}
      />
    </>
  );
}
