import { Id } from '@c/_generated/dataModel';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CollectTypeEnum } from '../../enums/tournament';

interface UIManagementStore {
  hidePhaserGame: boolean;
  isLoading: boolean;
  showResultStatusModal: boolean;
  selectedTid: Id<'tournaments'> | null;
  showCollectAnimationLayer: boolean;
  diamondPosition: { x: number; y: number } | null;
  dollarPosition: { x: number; y: number } | null;
  collectType: CollectTypeEnum | null;
}

export const uiManagementStore = create<UIManagementStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      hidePhaserGame: true,
      isLoading: false,
      showResultStatusModal: false,
      selectedTid: null,

      // collect rewards animation layer
      showCollectAnimationLayer: false,
      diamondPosition: null,
      dollarPosition: null,
      collectType: null,
      // ===============================
    }),
    { name: 'Solitaire4Win-UIManagementStore' },
  ),
);

export const closeGame = () =>
  uiManagementStore.setState({ hidePhaserGame: true });

export const showResultStatusModal = (tid: Id<'tournaments'>) =>
  uiManagementStore.setState({ showResultStatusModal: true, selectedTid: tid });

export const hideResultStatusModal = () =>
  uiManagementStore.setState({
    showResultStatusModal: false,
    selectedTid: null,
  });

export const openShowCollectAnimationLayer = () =>
  uiManagementStore.setState({ showCollectAnimationLayer: true });

export const closeShowAnimationLayer = () =>
  uiManagementStore.setState({ showCollectAnimationLayer: false });

export const setDiamondPosition = (x: number, y: number) =>
  uiManagementStore.setState({ diamondPosition: { x, y } });

export const setDollarPosition = (x: number, y: number) =>
  uiManagementStore.setState({ dollarPosition: { x, y } });

export const setCollectType = (type: CollectTypeEnum) =>
  uiManagementStore.setState({ collectType: type });
