import clsx from 'clsx';
import { useGameDebug } from '@/hooks/useGameDebug';
import { useInitPhaserGame } from '@/hooks/useInitPhaserGame';
import OrGameUI from '../organisms/OrGameUI';
import OrGameResultModal from '../organisms/modals/OrGameResultModal.tsx';
import { useEffect, useState } from 'react';
import { useQuery } from 'convex/react';
import { api } from '@c/_generated/api';
import { gameStore } from '@/zustand/gameStore';
import OrSubmitConfirm from '../organisms/OrSubmitConfirm';
import { uiManagementStore } from '@/zustand/uiManagementStore';
import { userStore } from '@/zustand/userStore';
import { replayStore } from '@/zustand/replayStore';

export function TmPhaserGame() {
  const [showResult, setShowResult] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const sessionId = userStore((state) => state.sessionId);
  const { gameId } = gameStore((state) => state);
  const gameResult = useQuery(api.game.functions.queryGameResult, {
    gameId,
    sessionId: sessionId ?? '',
  });

  useGameDebug();
  useInitPhaserGame();

  const { isLoading } = uiManagementStore((state) => state);
  const { isReplaying } = replayStore((state) => state);

  // handle game result when game is over
  useEffect(() => {
    if (gameResult?.isGameOver) {
      gameStore.setState({ gameResult });
      setShowConfirm(false);
      setShowResult(true);
    }
  }, [gameResult, isReplaying]);

  return (
    <div
      className={clsx('h-svh flex flex-col justify-center items-center', {
        'opacity-0': isLoading,
      })}
    >
      <>
        {/* Game */}
        <div id="game" className={clsx('h-full w-full')}></div>

        {!isReplaying && (
          <>
            {/* Game UI */}
            <OrGameUI openSubmitConfirm={setShowConfirm} />

            {/* Game Submit Modal */}
            <OrSubmitConfirm
              isOpen={showConfirm}
              openSubmitConfirm={setShowConfirm}
            />

            {/* Game Result Modal */}
            <OrGameResultModal
              isOpen={showResult}
              openGameResult={setShowResult}
              gameResult={gameResult}
              isSolved={gameResult?.solved ?? false}
            />
          </>
        )}
      </>
    </div>
  );
}
