import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IUserStore } from '@/../types/user';

interface IUserStoreActions {
  setSessionId: (sessionId: string | null) => void;
}

export const userStore = create<IUserStore & IUserStoreActions>()(
  devtools(
    (set) => ({
      crystalAmount: 0,
      cashAmount: 0,

      // Actions
      setSessionId: (sessionId: string | null) => {
        set({ sessionId: sessionId ?? '' });
      },
    }),
    { name: 'Solitaire4Win-UserStore' },
  ),
);
