export default function AtBadgeIcon({
  width = 20,
  height = 20,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 508 508"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <polygon
          fill="#FFD15C"
          points="417.4,245.5 466.7,208.1 417.4,170.6 445.7,115.5 385,103.3 386.6,41.3 326.7,56.6 301.3,0 254,40 206.7,0 181.3,56.6 121.4,41.2 123,103.3 62.3,115.5 90.6,170.6 41.3,208.1 90.6,245.5 62.3,300.7 123,312.9 121.4,374.9 181.3,359.6 206.7,416.2 254,376.2 301.3,416.2 326.7,359.6 386.6,374.9 385,312.9 445.7,300.7 "
        ></polygon>{' '}
        <g>
          {' '}
          <polygon
            fill="#FF7058"
            points="181.3,359.6 121.4,374.9 122.8,318.8 111,339.8 46.3,454.3 120.2,433.8 140.7,508 193.6,414.5 200.4,402.3 "
          ></polygon>{' '}
          <polygon
            fill="#FF7058"
            points="396.9,336.1 385.1,315.1 386.6,374.9 326.7,359.6 308.4,400.3 315.3,412.5 369.3,508 389.8,433.8 463.7,454.3 "
          ></polygon>{' '}
        </g>{' '}
        <g>
          {' '}
          <polygon
            fill="#F1543F"
            points="200.4,402.3 193.6,414.5 174.9,372.9 109.7,389.6 111,339.8 122.8,318.8 121.4,374.9 181.3,359.6 "
          ></polygon>{' '}
          <polygon
            fill="#F1543F"
            points="398.3,389.6 333.1,372.9 315.3,412.5 308.4,400.3 326.7,359.6 386.6,374.9 385.1,315.1 396.9,336.1 "
          ></polygon>{' '}
        </g>{' '}
        <ellipse
          fill="#F8B64C"
          cx="254"
          cy="208.1"
          rx="132.1"
          ry="132.5"
        ></ellipse>{' '}
        <polygon
          fill="#F2F2F2"
          points="222.5,276.3 172.6,226.3 195.7,203.1 222.5,230 312.3,139.8 335.4,163 "
        ></polygon>{' '}
      </g>
    </svg>
  );
}
