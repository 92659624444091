import { uiManagementStore } from '@/zustand/uiManagementStore.ts';
import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import AtPokerLoadingLottie from '../../atoms/AtPokerLoadingLottie.tsx';

export default function OrLoadingModal() {
  const { isLoading } = uiManagementStore((state) => state);

  return (
    <>
      <Modal
        isOpen={isLoading}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        placement="center"
        hideCloseButton
        classNames={{
          backdrop: 'bg-[#214742]',
        }}
      >
        <ModalContent className="bg-transparent shadow-none">
          <ModalBody>
            {/* <Spinner
              size="lg"
              label="Loading..."
              color="warning"
              classNames={{
                label: 'text-yellow-400 font-bold text-xl',
              }}
            /> */}
            <div className="flex justify-center items-center w-40 m-auto">
              <AtPokerLoadingLottie />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
