export default function AtMedalOneIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Flat">
          {' '}
          <g id="Color">
            {' '}
            <polygon
              fill="#212529"
              points="8.26 3 25.94 33.62 38.06 26.62 24.42 3 8.26 3"
            ></polygon>{' '}
            <path
              d="M38.06,26.62l-7.21-12.5-3.72,6.44a21.53,21.53,0,0,0-7,3l5.8,10Z"
              fill="#111315"
            ></path>{' '}
            <polygon
              fill="#dd051d"
              points="34.6 28.62 29.4 31.62 12.87 3 19.8 3 34.6 28.62"
            ></polygon>{' '}
            <polygon
              fill="#212529"
              points="39.58 3 25.94 26.62 38.06 33.62 55.74 3 39.58 3"
            ></polygon>{' '}
            <path
              d="M34.6,28.62l-6.06-10.5-1.42,2.46a21.44,21.44,0,0,0-3.46,1.1l5.74,9.94Z"
              fill="#a60416"
            ></path>{' '}
            <path
              d="M43.86,23.58a21.46,21.46,0,0,0-14.17-3.45l-3.75,6.49,12.12,7Z"
              fill="#111315"
            ></path>{' '}
            <polygon
              fill="#dd051d"
              points="51.13 3 34.6 31.62 29.4 28.62 44.2 3 51.13 3"
            ></polygon>{' '}
            <path
              d="M34.6,31.62l5.74-9.94a21.41,21.41,0,0,0-6-1.55L29.4,28.62Z"
              fill="#a60416"
            ></path>{' '}
            <circle cx="32" cy="41.5" fill="#fccd1d" r="19.5"></circle>{' '}
            <circle cx="32" cy="41.5" fill="#f9a215" r="14.5"></circle>{' '}
            <path
              d="M34.13,43.63V33H29.88a3.19,3.19,0,0,1-3.19,3.19H25.63v4.25h4.25v3.19a2.13,2.13,0,0,1-2.13,2.12H25.63V50H38.38V45.75H36.25A2.12,2.12,0,0,1,34.13,43.63Z"
              fill="#fccd1d"
            ></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
