import { convex } from '@/utils/convex';
import { api } from '@c/_generated/api';
import { Id } from '@c/_generated/dataModel';
import { TVActionPayload } from '../../types/game';
import { userStore } from '@/zustand/userStore';

export async function createGame() {
  const sessionId = userStore.getState().sessionId;

  if (!sessionId) {
    throw new Error('No session id');
  }

  return await convex.mutation(api.game.functions.createGame, {
    sessionId: sessionId,
  });
}

export async function getGameById(
  gameId: Id<'games'> = 'jd7c7y8k8c9k4yy7tzsh70r0616wa770' as Id<'games'>,
) {
  return await convex.query(api.game.functions.getReplayGameById, {
    gameId,
  });
}

export async function sendAction(action: TVActionPayload) {
  const sessionId = getSessionId();

  return await convex.mutation(api.game.functions.verifyAction, {
    action,
    sessionId,
  });
}

export async function submitGame(gameId: Id<'games'>) {
  const sessionId = getSessionId();

  return await convex.mutation(api.game.functions.submitGame, {
    gameId,
    sessionId,
  });
}

export async function getUnfinishedGame() {
  const sessionId = userStore.getState().sessionId;

  if (!sessionId) {
    throw new Error('No session id');
  }

  return await convex.query(api.game.functions.getUnfinishedGame, {
    sessionId,
  });
}

function getSessionId() {
  const sessionId = userStore.getState().sessionId;

  if (!sessionId) {
    throw new Error('No session id');
  }

  return sessionId;
}
