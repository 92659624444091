import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface InteractionStore {
  canInteract: boolean;
}

export const interactionStore = create<InteractionStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      canInteract: true,
    }),
    { name: 'Solitaire4Win-InteractionStore' },
  ),
);

// derived state
export const isInteractive = () => interactionStore.getState().canInteract;
