export default function AtDollarIcon() {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path
              style={{ fill: '#74D24F' }}
              d="M496,112H16c-8.82,0-16,7.18-16,16v256c0,8.82,7.18,16,16,16h480c8.82,0,16-7.18,16-16V128 C512,119.18,504.82,112,496,112z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              style={{ fill: '#FFEFBC' }}
              d="M472,192c-22.055,0-40-17.945-40-40c0-4.422-3.578-8-8-8H88c-4.422,0-8,3.578-8,8 c0,22.055-17.945,40-40,40c-4.422,0-8,3.578-8,8v112c0,4.422,3.578,8,8,8c22.055,0,40,17.945,40,40c0,4.422,3.578,8,8,8h336 c4.422,0,8-3.578,8-8c0-22.055,17.945-40,40-40c4.422,0,8-3.578,8-8V200C480,195.578,476.422,192,472,192z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              style={{ fill: '#869B55' }}
              d="M128,256c0,48.221,26.691,90.175,66.081,112h123.839C357.309,346.175,384,304.221,384,256 s-26.691-90.175-66.081-112H194.08C154.691,165.825,128,207.779,128,256z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              style={{ fill: '#FFEFBC' }}
              d="M256,244c-16.023,0-28-8.445-28-16c0-7.555,11.977-16,28-16s28,8.445,28,16c0,6.625,5.375,12,12,12 s12-5.375,12-12c0-19.236-16.824-34.862-40-38.932V184c0-6.625-5.375-12-12-12s-12,5.375-12,12v5.068 c-23.176,4.07-40,19.695-40,38.932c0,22.43,22.844,40,52,40c16.023,0,28,8.445,28,16c0,7.555-11.977,16-28,16s-28-8.445-28-16 c0-6.625-5.375-12-12-12s-12,5.375-12,12c0,19.236,16.824,34.862,40,38.932V328c0,6.625,5.375,12,12,12s12-5.375,12-12v-5.068 c23.176-4.07,40-19.695,40-38.932C308,261.57,285.156,244,256,244z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#74D24F',
              }}
              cx="80"
              cy="256"
              r="24"
            ></circle>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#74D24F',
              }}
              cx="432"
              cy="256"
              r="24"
            ></circle>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#869B55',
              }}
              cx="40"
              cy="152"
              r="16"
            ></circle>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#869B55',
              }}
              cx="472"
              cy="152"
              r="16"
            ></circle>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#869B55',
              }}
              cx="40"
              cy="360"
              r="16"
            ></circle>{' '}
          </g>{' '}
          <g>
            {' '}
            <circle
              style={{
                fill: '#869B55',
              }}
              cx="472"
              cy="360"
              r="16"
            ></circle>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
