import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { TVActionPayload } from '../../types/game';

interface ReplayStore {
  isReplaying: boolean;
  actions: TVActionPayload[];
  currentIndex: number;
  replayEndTime: number;
}

export const replayStore = create<ReplayStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      isReplaying: false,
      actions: [],
      currentIndex: 0,
      replayEndTime: 0,
    }),
    { name: 'Solitaire4Win-ReplayStore' },
  ),
);

export const isReplaying = () => replayStore.getState().isReplaying;
export const actionIndex = () => replayStore.getState().currentIndex;
export const currentAction = () =>
  replayStore.getState().actions[replayStore.getState().currentIndex];

export const replayEndTime = () => replayStore.getState().replayEndTime;
export const clearReplayStore = () => {
  replayStore.setState({
    isReplaying: false,
    actions: [],
    currentIndex: 0,
  });
};
