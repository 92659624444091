import { CARD_HEIGHT, CARD_WIDTH } from '@/game/utils/constants';
import {
  CARD_DRAG,
  CARD_DRAG_END,
  CARD_DRAG_START,
  DECK_LEFT_TOP_CLICK,
  DECK_RIGHT_CLICK,
  TABLEAU_CLICK,
} from '@/game/utils/eventNames';
import { TPos, TVCard } from '@/../types/game';
import { AreaEnum } from '@/../enums/game';
import Phaser from 'phaser';
import { gameStore } from '@/zustand/gameStore';
import { EventBus } from '@/utils/eventBus';

export class CardSprite extends Phaser.GameObjects.Sprite {
  info: TVCard;
  pos: TPos = { x: 0, y: 0 };
  isDragging = false;
  isPointerDown = false;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame: string,
    //
    info: TVCard,
  ) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);

    this.setOrigin(0, 0);
    this.displayWidth = CARD_WIDTH;
    this.displayHeight = CARD_HEIGHT;

    this.info = info;

    this.setInteractive({ draggable: true });
    this.onPointerDown();
    this.onPointerUp();
    this.onDragStart();
    this.onDrag();
    this.onDragEnd();
  }

  updatePosition(x: number, y: number) {
    // this.x = x;
    // this.y = y;

    this.pos.x = x;
    this.pos.y = y;
  }

  updateCardInfo(info: TVCard) {
    this.info = info;
  }

  resetSize() {
    this.displayWidth = CARD_WIDTH;
    this.displayHeight = CARD_HEIGHT;
  }

  onPointerDown() {
    this.on(Phaser.Input.Events.POINTER_DOWN, async () => {
      this.isPointerDown = true;
      this.updateIsDragging(false);
    });
  }

  onPointerUp() {
    this.on(Phaser.Input.Events.POINTER_UP, () => {
      if (!this.isPointerDown) return;
      this.isPointerDown = false;

      // card is deck right
      if (this.info.area === AreaEnum.DeckRight) {
        EventBus.emit(DECK_RIGHT_CLICK);
        return;
      }

      // card is deck left top card
      if (this.checkCardIsTopOfDeckLeft(this.info) && !this.isDragging) {
        EventBus.emit(DECK_LEFT_TOP_CLICK, this.info);
        return;
      }

      // card is tableau and is the last card
      if (this.checkCardIsLastOfTableauCol(this.info) && !this.isDragging) {
        EventBus.emit(TABLEAU_CLICK, this.info);
        return;
      }
    });
  }

  onDragStart() {
    this.scene.input.on(
      Phaser.Input.Events.DRAG_START,
      (_pointer: Phaser.Input.Pointer, gameObject: CardSprite) => {
        if (this.isValidDrag(gameObject, this)) {
          this.updateIsDragging(false);
          EventBus.emit(CARD_DRAG_START, gameObject.info);
        }
      },
    );
  }

  onDrag() {
    this.scene.input.on(
      Phaser.Input.Events.DRAG,
      (
        _pointer: Phaser.Input.Pointer,
        gameObject: CardSprite,
        dragX: number,
        dragY: number,
      ) => {
        if (this.isValidDrag(gameObject, this)) {
          this.updateIsDragging(true);

          gameObject.x = dragX;
          gameObject.y = dragY;

          const deltaX = dragX - this.pos.x;
          const deltaY = dragY - this.pos.y;

          EventBus.emit(CARD_DRAG, gameObject.info, {
            x: deltaX,
            y: deltaY,
          });
        }
      },
    );
  }

  onDragEnd() {
    this.scene.input.on(
      Phaser.Input.Events.DRAG_END,
      (_pointer: Phaser.Input.Pointer, gameObject: CardSprite) => {
        if (gameObject === this && this.isDragging) {
          EventBus.emit(CARD_DRAG_END, gameObject.info);
        }
      },
    );
  }

  updateIsDragging(isDragging: boolean) {
    this.isDragging = isDragging;
  }

  checkCardIsLastOfTableauCol(card: TVCard): boolean {
    if (card.area !== AreaEnum.Tableau) return false;

    const tableau = gameStore.getState().game?.cards.tableau || [];
    const col = card.col;
    const row = card.row;

    if (tableau[col].length - 1 === row) {
      return true;
    }

    return false;
  }

  checkCardIsTopOfDeckLeft(card: TVCard): boolean {
    if (card.area !== AreaEnum.DeckLeft) return false;

    const deckLeft = gameStore.getState().game?.cards.deck[0] || [];
    if (deckLeft.length === 0) {
      return false;
    }

    const topCard = deckLeft[deckLeft.length - 1];

    return card.type === topCard.type && card.value === topCard.value;
  }

  isValidDrag(
    dragObject: Phaser.GameObjects.Sprite,
    gameObject: CardSprite,
  ): boolean {
    const validDeckLeft =
      dragObject === gameObject &&
      gameObject.info.area === AreaEnum.DeckLeft &&
      this.checkCardIsTopOfDeckLeft(gameObject.info);

    const validTableau =
      dragObject === gameObject &&
      gameObject.info.area === AreaEnum.Tableau &&
      !gameObject.info.hide;

    const validFoundation =
      dragObject === gameObject &&
      gameObject.info.area === AreaEnum.Foundation &&
      this.checkCardIsTopOfFoundation(gameObject.info);

    return validDeckLeft || validTableau || validFoundation;
  }

  checkCardIsTopOfFoundation(info: TVCard): boolean {
    const foundation = gameStore.getState().game?.cards.foundation;
    if (!foundation) return false;

    const targetFoundation = foundation[info.col];
    if (targetFoundation.length === 0) {
      return false;
    }

    const topCard = targetFoundation[targetFoundation.length - 1];
    return topCard.value === info.value && topCard.type === info.type;
  }
}
