import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { TVDataTournamentTypes } from '../../types/tournament';
import { TournamentTypesEnum } from '../../enums/tournament';

interface TournamentTypesStore {
  data: TVDataTournamentTypes[] | null;
  currentType: TournamentTypesEnum | null;
}

export const tournamentTypesStore = create<TournamentTypesStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      data: null,
      currentType: null,
    }),
    { name: 'Solitaire4Win-TournamentTypesStore' },
  ),
);
