import { COMBAT } from '@/game/scenes/combatScene';
import Phaser from 'phaser';

export const PRELOAD = 'preloadScene';
export const CARDS = 'cards';
export const BASE = 'base';

export class PreloadScene extends Phaser.Scene {
  constructor() {
    super(PRELOAD);
  }

  preload() {
    this.load.atlas(
      CARDS,
      '/assets/solitaire/images/cards.png',
      '/assets/solitaire/images/cards.json',
    );

    this.load.atlas(
      BASE,
      '/assets/solitaire/images/base.png',
      '/assets/solitaire/images/base.json',
    );
  }

  create() {
    this.scene.start(COMBAT);
  }
}
