export const GAME_WIDTH = 640;
export const GAME_HEIGHT = 960;

export const CARD_WIDTH = 300 * 0.33;
export const CARD_HEIGHT = (CARD_WIDTH / 300) * 424 * 0.82;

export const CARD_AREA_PADDING_X = 2;
export const CARD_COL_MARGIN = 32;
export const CARD_COL_HIDE_MARGIN = 12;

export const TABLEAU_Y = 230;
export const FOUNDATION_Y = 90;

export const DECK_LEFT_OFFSET = 32;
export const DECK_GAP = 15;

export const BASE_CARD_ALPHA = 1;

export const CARD_MOVING_DURATION = 110;
export const CARD_FLIP_DURATION = 110;

export const DESKTOP = {};
export const MOBILE = {};
