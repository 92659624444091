import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';
import clsx from 'clsx';
import { TGameResultRes } from '../../../../types/game.ts';
import { EventBus } from '@/utils/eventBus.ts';
import { GAME_OVER } from '@/game/utils/eventNames.ts';
import {
  showResultStatusModal,
  uiManagementStore,
} from '@/zustand/uiManagementStore.ts';
import { gameStore } from '@/zustand/gameStore.ts';
import AtClockIcon from '../../atoms/icons/AtClockIcon.tsx';
import AtGoalIcon from '../../atoms/icons/AtGoalIcon.tsx';
import AtBadgeIcon from '../../atoms/icons/AtBadgeIcon.tsx';
import MlLineScore from '../../molecules/MlLineScore.tsx';
import { useRef, useState } from 'react';
import AtCongratsLottie from '../../atoms/AtCongratsLottie.tsx';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { interactionStore } from '@/zustand/interactionStore.ts';

export interface IOrGameResultModalProps {
  isOpen?: boolean;
  openGameResult: (open: boolean) => void;
  gameResult?: TGameResultRes;
  isSolved?: boolean;
}

export default function OrGameResultModal({
  isOpen,
  openGameResult,
  gameResult,
  isSolved,
}: IOrGameResultModalProps) {
  const [showBaseScore, setShowBaseScore] = useState(false);
  const [showTimeBonus, setShowTimeBonus] = useState(false);
  const [showTotalScore, setShowTotalScore] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);

  const scoreRefs = useRef<(HTMLDivElement | null)[]>([null, null, null]);

  const handleConfirm = () => {
    openGameResult(false);
    EventBus.emit(GAME_OVER);
    // clean after game over
    gameStore.setState({ game: null, gameId: null, gameResult: null });
    uiManagementStore.setState({ hidePhaserGame: true });
    interactionStore.setState({ canInteract: true });

    if (gameResult?.tid) {
      showResultStatusModal(gameResult?.tid);
    }
  };

  useGSAP(() => {
    if (!isOpen) return;

    const tl = gsap.timeline({
      onComplete: () => {
        setShowCongrats(true);
      },
    });

    scoreRefs.current.forEach((div, index) => {
      tl.fromTo(
        div,
        {
          opacity: 0,
        },
        {
          duration: 0.5,
          opacity: 1,
          ease: 'power1.inOut',
          onComplete: () => {
            if (index === 0) setShowBaseScore(true);
            if (index === 1) setShowTimeBonus(true);
            if (index === 2) setShowTotalScore(true);
          },
        },
        index * 0.5,
      );
    });
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        placement="center"
        hideCloseButton
        backdrop="blur"
        classNames={{
          backdrop: 'bg-[#214742] bg-opacity-80',
        }}
      >
        <ModalContent className={clsx('w-4/5 bg-[#1E4A49] overflow-hidden')}>
          {(onClose) => (
            <>
              {showCongrats && (
                <div className="fixed overflow-hidden top-0 bottom-0 left-0 right-0 pointer-events-none">
                  <AtCongratsLottie />
                </div>
              )}
              <ModalHeader className="flex gap-1 justify-center text-yellow-400 bg-[#2C6C60] font-black text-2xl">
                Your Score
              </ModalHeader>
              <ModalBody className={clsx('text-white font-bold py-5')}>
                {isSolved && (
                  <div className="text-2xl flex flex-col justify-center items-center gap-2 text-yellow-400">
                    <p>🎉🎉🎉</p>
                    <p className="text-stroke-3">You solved the game!</p>
                  </div>
                )}
                <div ref={(el) => (scoreRefs.current[0] = el)}>
                  <MlLineScore
                    icon={<AtGoalIcon width={25} height={25} />}
                    text="Base Score:"
                    score={gameResult?.baseScore || 0}
                    play={showBaseScore}
                  />
                </div>
                <div ref={(el) => (scoreRefs.current[1] = el)}>
                  <MlLineScore
                    icon={<AtClockIcon width={25} height={25} />}
                    text="Time Bonus:"
                    score={gameResult?.timeBonus || 0}
                    play={showTimeBonus}
                  />
                </div>
                <hr className="border-[#6EE9D4] my-2 border-t-2 mx-5" />
                <div ref={(el) => (scoreRefs.current[2] = el)}>
                  <MlLineScore
                    largeScore
                    icon={<AtBadgeIcon width={25} height={25} />}
                    text="Total Score:"
                    score={gameResult?.totalScore || 0}
                    play={showTotalScore}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="justify-center bg-[#2C6C60]">
                <Button
                  color="warning"
                  onPress={() => {
                    onClose();
                    handleConfirm();
                  }}
                  className={clsx('text-white font-black')}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
