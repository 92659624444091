import { interactionStore } from '@/zustand/interactionStore';
import {
  TVActionPayload,
  TActionRes,
  TGameActionHandler,
} from '../../../types/game';
import { sendAction } from '../../services/game';
import { gameStore } from '@/zustand/gameStore';
import { EventBus } from '@/utils/eventBus';
import { ADD_SCORE, GAME_SOLVED, MINUS_SCORE } from '../utils/eventNames';
import { isReplaying } from '@/zustand/replayStore';
import { replayActionsHandler } from './replayActions';

/**
 * @description Action wrapper - HOF: Add timestamp to action and handle loading state
 * @param {TGameActionHandler} handler
 * @returns {TGameActionHandler}
 */
function withAction(handler: TGameActionHandler): TGameActionHandler {
  return async (action: TVActionPayload) => {
    // replay actions
    if (isReplaying()) {
      return replayActionsHandler();
    } else {
      // normal actions
      action.timestamp = Date.now();
      let res: TActionRes;
      const currentScore = gameStore.getState()?.game?.score || 0;

      try {
        interactionStore.setState({ canInteract: false });
        res = await handler(action);

        // ==== handle game solved ====
        const isSolved = res.game?.solved || false;
        if (isSolved) {
          EventBus.emit(GAME_SOLVED);
        }
        // ============================

        // ==== handle score change ====
        const newScore = res.game?.score || 0;
        if (newScore > currentScore) {
          EventBus.emit(ADD_SCORE, newScore - currentScore);
        }
        if (newScore < currentScore) {
          EventBus.emit(MINUS_SCORE, currentScore - newScore);
        }
        // =============================
      } catch (error) {
        interactionStore.setState({ canInteract: true });
        console.error('Error in action handler', error);
        throw error;
      }

      return res;
    }
  };
}

async function actionHandler(action: TVActionPayload): Promise<TActionRes> {
  const actionRes = await sendAction({
    ...action,
  });

  return actionRes;
}

export const gameActionHandler = withAction(actionHandler);
