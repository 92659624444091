import { convex } from '@/utils/convex';
import { api } from '@c/_generated/api';
import { userStore } from '@/zustand/userStore';
import { TournamentTypesEnum } from '../../enums/tournament';
import { Id } from '@c/_generated/dataModel';

export async function joinTournament(type: TournamentTypesEnum | undefined) {
  const sessionId = userStore.getState().sessionId;

  if (!sessionId) {
    throw new Error('No session id');
  }

  return await convex.mutation(api.tournament.functions.joinTournament, {
    type,
    sessionId,
  });
}

export async function collectOneTournamentReward(tid: Id<'tournaments'>) {
  const sessionId = userStore.getState().sessionId;

  if (!sessionId) {
    throw new Error('No session id');
  }

  return await convex.mutation(
    api.tournament.functions.collectTournamentReward,
    {
      tid,
      sessionId,
    },
  );
}
