import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ITournamentResultsStore } from '../../types/tournament';

export const tournamentResultsStore = create<ITournamentResultsStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      ongoingTournament: null,
      nonCollectedTournaments: null,
      collectedTournaments: null,
    }),
    { name: 'Solitaire4Win-TournamentResultsStore' },
  ),
);
