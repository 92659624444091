// ==== game interaction ====
export const CARD_DRAG_START = 'CARD_DRAG_START';
export const CARD_DRAG = 'CARD_DRAG';
export const CARD_DRAG_END = 'CARD_END';

export const DECK_RIGHT_CLICK = 'DECK_RIGHT_CLICK';
export const DECK_BASE_CLICK = 'DECK_BASE_CLICK';
export const DECK_LEFT_TOP_CLICK = 'DECK_LEFT_TOP_CLICK';
export const TABLEAU_CLICK = 'TABLEAU_CLICK';
export const UNDO_BTN_CLICK = 'UNDO_BTN_CLICK';
// ============================

export const GAME_OVER = 'GAME_OVER';
export const START_GAME = 'START_GAME';

export const DISABLE_INPUT = 'DISABLE_INPUT';
export const ENABLE_INPUT = 'ENABLE_INPUT';

export const PAUSE_GAME = 'PAUSE_GAME';
export const RESUME_GAME = 'RESUME_GAME';

export const REMOVE_LOADING_FROM_GAME = 'REMOVE_LOADING_FROM_GAME';

export const ADD_SCORE = 'ADD_SCORE';
export const MINUS_SCORE = 'MINUS_SCORE';

export const GAME_SOLVED = 'GAME_SOLVED';

export const START_REPLAY = 'START_REPLAY';
export const STOP_REPLAY = 'STOP_REPLAY';
