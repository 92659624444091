import { Badge, Button } from '@nextui-org/react';
import AtShopIcon from '../atoms/icons/AtShopIcon';
import AtHomeIcon from '../atoms/icons/AtHomeIcon';
import AtAchievementsIcon from '../atoms/icons/AtAchievementsIcon';
import Slider from 'react-slick';
import clsx from 'clsx';
import { tournamentResultsStore } from '@/zustand/tournamentResultsStore';

export interface OrNavProps {
  sliderRef: React.RefObject<Slider>;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}

const activeColor = '#FBD751';
const inactiveColor = '#FAF5D9';

export default function OrNav({
  sliderRef,
  currentIndex,
  setCurrentIndex,
}: OrNavProps) {
  const isShop = currentIndex === 0;
  const isHome = currentIndex === 1;
  const isResults = currentIndex === 2;

  const { nonCollectedTournaments } = tournamentResultsStore((state) => state);

  const resultTabButton = (
    <Button
      isIconOnly
      radius="none"
      className={clsx('bg-transparent !outline-none !focus:outline-none')}
      onPress={() => {
        sliderRef.current?.slickGoTo(2);
        setCurrentIndex(2);
      }}
    >
      <AtAchievementsIcon
        fill={isResults ? activeColor : inactiveColor}
        play={isResults}
      />
    </Button>
  );

  return (
    <div className="pt-2 pb-3 flex w-full justify-center items-end gap-10 bg-[#214742]">
      <div className={clsx('flex flex-col items-center ')}>
        <Button
          isIconOnly
          radius="none"
          className={clsx('bg-transparent !outline-none !focus:outline-none')}
          onPress={() => {
            sliderRef.current?.slickGoTo(0);
            setCurrentIndex(0);
          }}
        >
          <AtShopIcon
            fill={isShop ? activeColor : inactiveColor}
            play={isShop}
          />
        </Button>
      </div>
      <Button
        isIconOnly
        radius="none"
        className={clsx('bg-transparent !outline-none !focus:outline-none')}
        onPress={() => {
          sliderRef.current?.slickGoTo(1);
          setCurrentIndex(1);
        }}
      >
        <AtHomeIcon fill={isHome ? activeColor : inactiveColor} play={isHome} />
      </Button>

      {nonCollectedTournaments && nonCollectedTournaments.length > 0 ? (
        <Badge
          content={nonCollectedTournaments.length}
          size="sm"
          showOutline={false}
          color="danger"
        >
          {resultTabButton}
        </Badge>
      ) : (
        <>{resultTabButton}</>
      )}
    </div>
  );
}
