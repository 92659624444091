import Lottie from 'lottie-react';
import animationData from '@/assets/lottie/congrats.json';

export default function AtCongratsLottie() {
  return (
    <div className="w-full h-full pointer-events-none flex justify-center scale-150">
      <Lottie className="max-w-3xl" animationData={animationData} />
    </div>
  );
}
