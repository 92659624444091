export default function AtLeaderboardIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title>list-order</title> <desc>Created with sketchtool.</desc>{' '}
        <g
          id="text-edit"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {' '}
          <g id="list-order" fill="#8F5F28">
            {' '}
            <path
              d="M10,4 L20,4 C20.5522847,4 21,4.44771525 21,5 C21,5.55228475 20.5522847,6 20,6 L10,6 C9.44771525,6 9,5.55228475 9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,11 L20,11 C20.5522847,11 21,11.4477153 21,12 C21,12.5522847 20.5522847,13 20,13 L10,13 C9.44771525,13 9,12.5522847 9,12 C9,11.4477153 9.44771525,11 10,11 Z M10,18 L20,18 C20.5522847,18 21,18.4477153 21,19 C21,19.5522847 20.5522847,20 20,20 L10,20 C9.44771525,20 9,19.5522847 9,19 C9,18.4477153 9.44771525,18 10,18 Z M4.5,4 C4.22385763,4 4,3.77614237 4,3.5 C4,3.22385763 4.22385763,3 4.5,3 L5.5,3 C5.78000021,3 6,3.22000003 6,3.5 L6,6.5 C6,6.77614237 5.77614237,7 5.5,7 C5.22385763,7 5,6.77614237 5,6.5 L5,4.0148508 L4.5,4 Z M4.5,10 C4.5,10 6.5,10 6.5,10 C6.78000021,10 7,10.2200003 7,10.5 C7,10.5 7,10.6666667 7,11 L5.30000019,13 C6.10000006,13 6.5,13 6.5,13 C6.78000021,13 7,13.2200003 7,13.5 C7,13.7799997 6.77557563,14 6.5,14 C6.5,14 4.5,14 4.5,14 C4.21999979,14 4,13.7799997 4,13.5 C4,13.5 4,13.3333333 4,13 L5.69999981,11 C4.89999994,11 4.5,11 4.5,11 C4.21999979,11 4,10.7799997 4,10.5 C4,10.2200003 4.19889333,10 4.5,10 Z M6.5,21 L4.5,21 C4.22385763,21 4,20.7761424 4,20.5 C4,20.2238576 4.22385763,20 4.5,20 L6,20 L6,19.5 L4.5,19.5 C4.22385763,19.5 4,19.2761424 4,19 C4,18.7238576 4.22385763,18.5 4.5,18.5 L6,18.5 L6,18 L4.5,18 C4.22385763,18 4,17.7761424 4,17.5 C4,17.2238576 4.22385763,17 4.5,17 L6.5,17 C6.77614237,17 7,17.2238576 7,17.5 L7,20.5 C7,20.7761424 6.77614237,21 6.5,21 Z"
              id="Shape"
            >
              {' '}
            </path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
}
