import clsx from 'clsx';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { gameStore } from '@/zustand/gameStore';
import { Button } from '@nextui-org/react';
import AtExitIcon from '../atoms/icons/AtExitIcon';
// import AtGearIcon from '../atoms/icons/AtGearIcon';
import AtUndoIcon from '../atoms/icons/AtUndoIcon';
import { EventBus } from '@/utils/eventBus';
import { DISABLE_INPUT, UNDO_BTN_CLICK } from '@/game/utils/eventNames';
import { isInteractive } from '@/zustand/interactionStore';

export interface IOrGameUIProps {
  openSubmitConfirm: (open: boolean) => void;
}

export default function OrGameUI({ openSubmitConfirm }: IOrGameUIProps) {
  const isDev = import.meta.env.MODE === 'development';
  const { width = 0, height = 0 } = useWindowSize();
  const { game } = gameStore((state) => state);

  const basedOnWidth = useMemo(() => {
    const screenRatio = width / height;
    const gameRatio = 640 / 960;

    if (screenRatio > gameRatio) {
      return false;
    }
    return true;
  }, [height, width]);

  if (!game) {
    return null;
  }

  const undoActionHandler = () => {
    if (!game.canUndo || !isInteractive()) return;
    EventBus.emit(UNDO_BTN_CLICK);
  };

  return (
    <div
      className={clsx(
        'absolute aspect-[640/960] pointer-events-none flex flex-col justify-end',
        {
          'h-svh': !basedOnWidth,
          'w-full': basedOnWidth,
          'border-2': isDev,
        },
      )}
    >
      <div
        className={clsx('flex justify-center pb-4 pointer-events-auto gap-12', {
          border: isDev,
        })}
      >
        <div className="flex flex-col justify-center items-center">
          <Button
            isIconOnly
            color="success"
            aria-label="Exit Game"
            variant="solid"
            className="rounded-full opacity-50"
            onClick={() => {
              openSubmitConfirm(true);
              EventBus.emit(DISABLE_INPUT);
            }}
          >
            <AtExitIcon />
          </Button>
          <span className="text-white text-xs">Exit</span>
        </div>

        {/* <Button
          isIconOnly
          color="success"
          aria-label="Game Settings"
          className="rounded-full opacity-50"
        >
          <AtGearIcon />
        </Button> */}
        <div className="flex flex-col justify-center items-center">
          <Button
            isIconOnly
            color={game.canUndo ? 'success' : 'default'}
            aria-label="Undo Move"
            className="rounded-full opacity-50"
            isDisabled={!game.canUndo}
            onClick={undoActionHandler}
          >
            <AtUndoIcon />
          </Button>
          <span
            className={clsx('text-white text-xs', {
              'text-gray-400': !game.canUndo,
            })}
          >
            Undo
          </span>
        </div>
      </div>
    </div>
  );
}
