export default function AtSpadeIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#000000"
          d="M255.344 17.25l-8.47 22.094C227.7 89.27 185.52 123.008 146.095 157.156c-39.426 34.148-76.808 69.704-78.53 123.656-1.82 56.95 34.185 93.41 76.874 101.75 38.584 7.54 82.765-6.755 110.812-43.843 27.75 40.234 71.347 53.59 109.28 44.155 41.744-10.382 76.882-47.91 78.564-101.47.896-28.52-8.002-52.38-22.063-72.686-14.06-20.307-33.06-37.34-53.03-54.064-39.94-33.445-83.52-65.632-103.75-115.5l-8.906-21.906zm.47 47.563C280.483 109.94 321.313 139.954 356 169c18.616 15.588 35.503 30.922 47.72 47.656-15.248-15.34-36.617-25.87-62.814-27.78 14.24 7.433 22.045 20.73 25.625 41.25-22.92-22.078-45.28-23.984-70.217-3.345 25.26-5.27 45.018 23.237 28.062 43.532-17.233 20.63-51.305.477-52.563-28.937-17.415 10.73-14.48 45.57-41.843 41.72 6.936-31.95-1.667-52.925-30.126-64.658 4.436 7.454 5.53 17.462 3.344 26.75-4.07 17.258-19.666 28.24-35.125 23.407-18.04-5.553-23.724-24.72-18.375-48.5 3.51-15.598 12.686-27.463 23.968-35.125-18.27.81-34.315 6.753-47.562 16.124 9.7-9.987 20.65-19.767 32.25-29.813 34.406-29.8 73.928-61.16 97.47-106.468zm24.717 108.53c-24.21.433-40.19 22.44-32.342 55.25 14.05-38.053 44.226-41.72 81.437-30.405-17.092-17.59-34.568-25.103-49.094-24.844zM256.845 366.22l-60.03 119.25h120.06l-60.03-119.25z"
        ></path>
      </g>
    </svg>
  );
}
