import { useEffect } from 'react';

export function useGameDebug() {
  const isDev = import.meta.env.MODE === 'development';

  useEffect(() => {
    if (!isDev) return;
    // change style
    const canvas = document.getElementById('game')
      ?.firstChild as HTMLCanvasElement;
    if (canvas) {
      const style = canvas.style;
      style.border = '1px solid #FF0000';
    }
  }, [isDev]);
}
