import { Avatar, Button } from '@nextui-org/react';
import AtHamburgerIcon from '../atoms/icons/AtHamburgerIcon';
import { useQuery } from 'convex/react';
import { api } from '@c/_generated/api';
import { userStore } from '@/zustand/userStore';
import { useEffect, useRef } from 'react';
import { createUser } from '@/services/user';
import AtDiamondIcon from '../atoms/icons/AtDiamondIcon';
import AtDollarIcon from '../atoms/icons/AtDollarIcon';
import { transferToK } from '@/utils/unitTransfer';
import {
  setDiamondPosition,
  setDollarPosition,
  uiManagementStore,
} from '@/zustand/uiManagementStore';

export default function OrTopBar() {
  const sessionId = userStore((state) => state.sessionId);

  const { showCollectAnimationLayer } = uiManagementStore((state) => state);

  const userInfo = userStore((state) => state);

  const userInfoRes = useQuery(
    api.user.functions.queryUser,
    showCollectAnimationLayer
      ? 'skip'
      : {
          sessionId: sessionId || '',
        },
  );

  const diamondRef = useRef<HTMLSpanElement>(null);
  const dollarRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (userInfoRes) {
      userStore.setState({ ...userInfoRes });
    }

    if (userInfoRes && !userInfoRes.hasBeenCreated) {
      createUser();
    }
  }, [userInfoRes]);

  // set diamond and dollar position
  useEffect(() => {
    const setPositions = () => {
      if (diamondRef.current && dollarRef.current) {
        const diamondRect = diamondRef.current.getBoundingClientRect();
        const dollarRect = dollarRef.current.getBoundingClientRect();

        setDiamondPosition(diamondRect.x, diamondRect.y);
        setDollarPosition(dollarRect.x, dollarRect.y);
      }
    };
    setPositions();

    // resize event
    window.addEventListener('resize', setPositions);

    return () => {
      window.removeEventListener('resize', setPositions);
    };
  }, [diamondRef, dollarRef]);

  return (
    <div className="w-full p-1 pb-0 flex justify-between relative">
      <Avatar
        isBordered
        color="danger"
        src={`/assets/solitaire/images/avatars/${userInfo?.avatar}.png`}
      />
      <div className="flex items-center flex-1 pl-6">
        {/* crystal amount */}
        <div className="flex items-center gap-1 w-24">
          <span className="w-5" ref={diamondRef}>
            <AtDiamondIcon />
          </span>
          <span className="">{transferToK(userInfo?.crystalAmount ?? 0)}</span>
        </div>
        {/* cash amount */}
        <div className="flex items-center gap-1 w-24">
          <span className="w-5" ref={dollarRef}>
            <AtDollarIcon />
          </span>
          <span className="">{transferToK(userInfo?.cashAmount ?? 0)}</span>
        </div>
      </div>
      <Button
        isIconOnly
        className="bg-[#B776F8] rounded-full p-1 !outline-none !focus:outline-none"
        size="sm"
      >
        <AtHamburgerIcon />
      </Button>
    </div>
  );
}
