import AtCrownIcon from '../atoms/icons/AtCrownIcon';

export interface IMlResultCardRankProps {
  rank: number; // start from 0
}

export default function MlResultCardRank({ rank }: IMlResultCardRankProps) {
  if (rank === -1) return;

  if (rank === 0) {
    return (
      <div className="bg-[#EDB74C] font-extrabold text-white text-xl rounded-full w-6 h-6 flex justify-center items-center border relative">
        <span className="absolute w-7 -top-5 -rotate-12 right-1">
          <AtCrownIcon />
        </span>
        <span>1</span>
      </div>
    );
  }

  if (rank === 1) {
    return (
      <div className="bg-[#B4C7C7] font-extrabold text-white text-xl rounded-full w-6 h-6 flex justify-center items-center border relative">
        <span className="absolute w-7 -top-5 -rotate-12 right-1">
          <AtCrownIcon fill="#A0AAA5" />
        </span>
        <span>2</span>
      </div>
    );
  }

  if (rank === 2) {
    return (
      <div className="bg-[#DA8650] font-extrabold text-white text-xl rounded-full w-6 h-6 flex justify-center items-center border relative">
        <span className="absolute w-7 -top-5 -rotate-12 right-1">
          <AtCrownIcon fill="#F39443" />
        </span>
        <span>3</span>
      </div>
    );
  }

  return (
    <div className="bg-[#F1DEAF] font-extrabold text-white text-xl rounded-full w-6 h-6 flex justify-center items-center border">
      {rank + 1}
    </div>
  );
}
