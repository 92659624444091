import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Avatar,
} from '@nextui-org/react';
import AtCloseIcon from '../../../atoms/icons/AtCloseIcon.tsx';
import clsx from 'clsx';
import { Id } from '@c/_generated/dataModel';
import { hideResultStatusModal } from '@/zustand/uiManagementStore.ts';
import { useQuery } from 'convex/react';
import { api } from '@c/_generated/api';
import { userStore } from '@/zustand/userStore.ts';
import { tournamentTypesStore } from '@/zustand/tournamentTypesStore.ts';
import { TournamentStatusEnum } from '../../../../../enums/tournament.ts';

export interface IOrResultStatusModalProps {
  isOpen: boolean;
  tid: Id<'tournaments'> | null;
}

export default function OrResultStatusModal({
  isOpen,
  tid,
}: IOrResultStatusModalProps) {
  const sessionId = userStore((state) => state.sessionId);
  const resultStatus = useQuery(
    api.tournament.functions.getTournamentStatusById,
    tid
      ? {
          tid: tid,
          sessionId: sessionId || '',
        }
      : 'skip',
  );

  const { data: tTypesData } = tournamentTypesStore((state) => state);
  const { uid: userId } = userStore((state) => state);

  const currentTypeData = tTypesData?.find(
    (t) => t.type === resultStatus?.type,
  );

  if (!resultStatus || !resultStatus?.players) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        placement="center"
        onOpenChange={(open) => {
          if (!open) {
            hideResultStatusModal();
          }
        }}
        classNames={{
          closeButton: 'p-0 !outline-none !focus:outline-none',
        }}
        closeButton={
          <Button isIconOnly size="sm" className="" color="danger">
            <AtCloseIcon />
          </Button>
        }
      >
        <ModalContent className={clsx('w-4/5 bg-[#1E4A49] text-white')}>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center items-center text-yellow-400 bg-[#2C6C60] font-black text-2xl">
                {resultStatus?.status === TournamentStatusEnum.FINISHED ? (
                  <div>Leaderboard</div>
                ) : (
                  <div>Result Pending</div>
                )}
              </ModalHeader>
              <ModalBody
                className={clsx(
                  'text-brown font-bold flex flex-col justify-center items-center text-xl py-0 px-0 gap-1',
                )}
              >
                {/* tournament type */}
                <div className="bg-[#FBD259] w-full flex justify-center items-center text-[#905F27] font-bold text-2xl">
                  {currentTypeData?.title}
                </div>
                {/* scroll area */}
                <div className="w-full max-h-64 sm:max-h-80 flex flex-col gap-2 overflow-auto px-3 mb-1 hide-scroll-bar h-96">
                  {/* players */}
                  {resultStatus?.players.map((player, index) => (
                    <div
                      key={player.uid}
                      className={clsx(
                        'flex items-center px-2 py-1.5 gap-4 rounded-lg',
                        {
                          'bg-[#FBD285]': player.uid === userId,
                          'bg-[#F3E1C3]': player.uid !== userId,
                        },
                      )}
                    >
                      {/* rank */}
                      <div className="h-8 w-8 rounded-full flex justify-center items-center bg-black bg-opacity-20 font-extrabold">
                        {index + 1}
                      </div>
                      {/* avatar */}
                      <div>
                        <Avatar
                          isBordered
                          color="success"
                          size="lg"
                          src={`/assets/solitaire/images/avatars/${player.avatar}.png`}
                        />
                      </div>
                      <div className="flex flex-col text-base">
                        <div className="text-[#5E3A1C]">{player.name}</div>
                        <div className="bg-black bg-opacity-15 w-16 rounded-full pl-2">
                          {player.score}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="justify-center bg-[#2C6C60] flex">
                <Button
                  color="warning"
                  onClick={hideResultStatusModal}
                  className="text-white"
                >
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
