import { IGameStore } from '@/../types/game';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const gameStore = create<IGameStore>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_set) => ({
      gameId: null,
      game: null,
      gameResult: null,
    }),
    { name: 'Solitaire4Win-GameStore' },
  ),
);

export const gameIns = () => gameStore.getState().game;
export const gameCreatedTime = () => gameStore.getState().createdTime;
export const gameSubmissionTime = () => gameStore.getState().submissionTime;
export const cleanGameStore = () => {
  gameStore.setState({
    gameId: null,
    game: null,
    gameResult: null,
  });
};
