import { GAME_DURATION } from '@c/utils/constants';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

interface IUseTimer {
  endTimestamp: number;
}

export default function useTimer({ endTimestamp }: IUseTimer) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useInterval(() => {
    setCurrentTime(Date.now()); // Update every x milliseconds
  }, 500);

  // calculate the time remaining
  const timeRemaining = endTimestamp - currentTime;

  if (timeRemaining <= 0) {
    return { minutes: 0, seconds: 0 };
  }

  // Calculate the minutes and seconds
  const minutes = Math.floor(timeRemaining / 1000 / 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  return { minutes, seconds };
}

export function timerCalc(endTimestamp: number, isReplaying: boolean = false) {
  const currentTime = Date.now();
  const timeRemaining = endTimestamp - currentTime;
  let startGame = false;

  if (timeRemaining <= 0) {
    return { minutes: 0, seconds: 0 };
  }

  if (timeRemaining <= GAME_DURATION + 1500) {
    startGame = true;
  }

  if (isReplaying) {
    startGame = true;
  }

  const minutes = Math.floor(timeRemaining / 1000 / 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  return { minutes, seconds, startGame };
}
