export default function AtCloseIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g clipPath="url(#clip0_429_11083)">
          {' '}
          <path
            d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006"
            stroke="#ffffff"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>{' '}
        </g>{' '}
        <defs>
          {' '}
          <clipPath id="clip0_429_11083">
            {' '}
            <rect width="24" height="24" fill="white"></rect>{' '}
          </clipPath>{' '}
        </defs>{' '}
      </g>
    </svg>
  );
}
