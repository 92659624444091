import { api } from '@c/_generated/api';
import { useQuery } from 'convex/react';
import MlGameCard from '../molecules/MlGameCard';
import { useEffect } from 'react';
import { tournamentTypesStore } from '@/zustand/tournamentTypesStore';

export default function TmHome() {
  const allTournaments = useQuery(api.tournament.functions.listAllTournaments);

  useEffect(() => {
    tournamentTypesStore.setState({ data: allTournaments });
  }, [allTournaments]);

  return (
    <div className="h-full flex flex-col items-center">
      <div className="flex-1 w-full flex flex-col items-center sm:px-6">
        <div className="flex flex-col gap-3 w-full">
          {allTournaments?.map((t, i) => (
            <MlGameCard index={i} key={t.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
