export interface MlBannerProps {
  text: string;
  percentage?: number;
}

export default function MlBanner({ text, percentage = 2 }: MlBannerProps) {
  return (
    <div
      className="bg-[#ffcc00] flex justify-center items-center h-10 w-full shadow-xl"
      style={{
        clipPath: `polygon(0% 0%, ${percentage}% 50%, 0% 100%, 100% 100%, ${100 - percentage}% 50%, 100% 0%)`,
      }}
    >
      <span className="text-white text-lg font-bold text-stroke-1">{text}</span>
    </div>
  );
}
