import { ENABLE_INPUT, PAUSE_GAME } from '@/game/utils/eventNames';
import { submitGame } from '@/services/game';
import { EventBus } from '@/utils/eventBus';
import { gameStore } from '@/zustand/gameStore';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';
import clsx from 'clsx';

export interface IOrSubmitConfirmProps {
  isOpen?: boolean;
  openSubmitConfirm: (open: boolean) => void;
}

export default function OrSubmitConfirm({
  isOpen,
  openSubmitConfirm,
}: IOrSubmitConfirmProps) {
  const handleGameSubmit = async (onClose: () => void) => {
    onClose();

    const gameId = gameStore.getState().gameId;

    if (!gameId) {
      return;
    }

    EventBus.emit(PAUSE_GAME);
    await submitGame(gameId);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        placement="center"
        hideCloseButton
      >
        <ModalContent className={clsx('w-4/5 bg-[#DECAA5]')}>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-1 justify-center text-yellow-400 bg-[#2C6C60] font-black text-2xl">
                End Game
              </ModalHeader>
              <ModalBody
                className={clsx(
                  'text-brown font-bold flex flex-col justify-center items-center text-xl py-5',
                )}
              >
                <div>Do you want</div>
                <div>to end the game?</div>
              </ModalBody>
              <ModalFooter className="justify-center bg-[#2C6C60]">
                <Button
                  color="warning"
                  onPress={() => {
                    onClose();
                    openSubmitConfirm(false);
                    EventBus.emit(ENABLE_INPUT);
                  }}
                  className={clsx('text-white font-black')}
                >
                  PLAY ON
                </Button>
                <Button
                  color="success"
                  onPress={() => handleGameSubmit(onClose)}
                  className={clsx('text-white font-black')}
                >
                  SUBMIT
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
