import { tournamentTypesStore } from '@/zustand/tournamentTypesStore.ts';
import { uiManagementStore } from '@/zustand/uiManagementStore.ts';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';
import clsx from 'clsx';
import { MoneyTypeEnum, TournamentTypesEnum } from '../../../../enums/tournament.ts';
import { TVDataTournamentTypes } from '../../../../types/tournament.ts';
import AtCloseIcon from '../../atoms/icons/AtCloseIcon.tsx';
import AtMedalTwoIcon from '../../atoms/icons/AtMedalTwoIcon.tsx';
import AtMedalOneIcon from '../../atoms/icons/AtMedalOneIcon.tsx';
import AtMedalThirdIcon from '../../atoms/icons/AtMedalThirdIcon.tsx';
import AtDiamondIcon from '../../atoms/icons/AtDiamondIcon.tsx';
import AtGroupIcon from '../../atoms/icons/AtGroupIcon.tsx';
import { transferToK } from '@/utils/unitTransfer.ts';
import AtDollarIcon from '../../atoms/icons/AtDollarIcon.tsx';

export interface OrlGameCardModalProps {
  isOpen: boolean;
  modalToggle: (open: boolean) => void;
  data: TVDataTournamentTypes;
}

const SecondOrThird = ({
  index,
  rewardMoneyType: moneyType,
  amount,
}: {
  index: number;
  rewardMoneyType: MoneyTypeEnum;
  amount: number;
}) => {
  return (
    <div className="w-20 self-end pt-4 rounded bg-[#0000004D] flex flex-col items-center">
      <div>{index === 1 ? <AtMedalTwoIcon /> : <AtMedalThirdIcon />}</div>
      <div className="flex gap-1">
        <div className="w-5">
          {moneyType === MoneyTypeEnum.CASH ? (
            <AtDollarIcon />
          ) : (
            <AtDiamondIcon />
          )}
        </div>
        <span>
          {moneyType === MoneyTypeEnum.CASH
            ? `$${amount}`
            : transferToK(amount)}
        </span>
      </div>
    </div>
  );
};

export default function OrGameCardModal({
  isOpen,
  modalToggle,
  data,
}: OrlGameCardModalProps) {
  const handlePlay = () => {
    modalToggle(false);

    // select tournament type
    tournamentTypesStore.setState({
      currentType: data.type as TournamentTypesEnum,
    });

    // start game
    uiManagementStore.setState({
      hidePhaserGame: false,
      isLoading: true,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        placement="center"
        onOpenChange={(open) => {
          if (!open) {
            modalToggle(false);
          }
        }}
        classNames={{
          closeButton:
            'p-0 !outline-none !focus:outline-none active:bg-red-600',
        }}
        closeButton={
          <Button isIconOnly size="sm" color="danger" disableRipple>
            <AtCloseIcon />
          </Button>
        }
      >
        <ModalContent className={clsx('w-4/5 bg-[#1E4A49] text-white')}>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-1 justify-center text-yellow-400 bg-[#2C6C60] font-black text-2xl">
                {data.title}
              </ModalHeader>
              <ModalBody
                className={clsx(
                  'text-brown font-bold flex flex-col justify-center items-center text-xl py-5',
                )}
              >
                <div className="flex justify-center gap-2 w-full sm:w-2/3">
                  {/* 2rd */}
                  {data.rewardMoneyAmount.length > 1 && (
                    <SecondOrThird
                      index={1}
                      rewardMoneyType={data.rewardMoneyType as MoneyTypeEnum}
                      amount={data.rewardMoneyAmount[1]}
                    />
                  )}
                  {/* 1st */}
                  {data.rewardMoneyAmount.length > 0 && (
                    <div className="w-20 pt-4 rounded bg-[#0000004D] flex flex-col items-center gap-5">
                      <div>
                        <AtMedalOneIcon />
                      </div>
                      <div className="flex gap-1">
                        <div className="w-5">
                          {data.rewardMoneyType === MoneyTypeEnum.CASH ? (
                            <AtDollarIcon />
                          ) : (
                            <AtDiamondIcon />
                          )}
                        </div>
                        <span>
                          {data.rewardMoneyType === MoneyTypeEnum.CASH
                            ? `$${data.rewardMoneyAmount[0]}`
                            : transferToK(data.rewardMoneyAmount[0])}
                        </span>
                      </div>
                    </div>
                  )}
                  {/* 3rd */}
                  {data.rewardMoneyAmount.length > 2 && (
                    <SecondOrThird
                      index={2}
                      rewardMoneyType={data.rewardMoneyType as MoneyTypeEnum}
                      amount={data.rewardMoneyAmount[2]}
                    />
                  )}
                </div>

                {/* rank 4 */}
                {data.rewardMoneyAmount.length > 3 && (
                  <div className="rounded h-10 w-full sm:w-2/3 flex justify-between items-center px-4 bg-[#0000004D]">
                    <span>Rank #4</span>
                    <div className="flex gap-1">
                      <div className="w-5">
                        {data.rewardMoneyType === MoneyTypeEnum.CASH ? (
                          <AtDollarIcon />
                        ) : (
                          <AtDiamondIcon />
                        )}
                      </div>
                      <span>{data.rewardMoneyAmount[3]}</span>
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="justify-center bg-[#2C6C60] flex flex-col ">
                <div className="w-full sm:w-2/3 flex flex-col items-center justify-center mx-auto gap-4">
                  {/* players and entry fee */}
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col justify-center items-center">
                      <span>Players:</span>
                      <div className="bg-[#0000004D] rounded-full flex justify-center py-1 w-28 gap-2">
                        <div className="w-6">
                          <AtGroupIcon />
                        </div>
                        <span>{data.size}</span>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <span>Entry Fee:</span>
                      <div className="bg-[#0000004D] rounded-full flex justify-center py-1 w-28 gap-2">
                        <div className="w-6">
                          {data.moneyType === MoneyTypeEnum.CASH ? (
                            <AtDollarIcon />
                          ) : (
                            <AtDiamondIcon />
                          )}
                        </div>
                        <span>
                          {data.moneyType === MoneyTypeEnum.CASH
                            ? `$${data.moneyAmount}`
                            : transferToK(data.moneyAmount)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* play now button */}
                  <Button
                    color="warning"
                    onPress={() => {
                      onClose();
                      handlePlay();
                    }}
                    className={clsx('text-white font-black w-full')}
                  >
                    Play Now
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
