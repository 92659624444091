import { Button } from '@nextui-org/react';
import {
  CollectTypeEnum,
  MoneyTypeEnum,
  TournamentResultStatusEnum,
} from '../../../enums/tournament';
import AtDiamondIcon from '../atoms/icons/AtDiamondIcon';
import AtWinnerBannerIcon from '../atoms/icons/AtWinnerBannerIcon';
import clsx from 'clsx';
import { Id } from '@c/_generated/dataModel';
import { collectOneTournamentReward } from '@/services/tournament';
import {
  openShowCollectAnimationLayer,
  setCollectType,
} from '@/zustand/uiManagementStore';

export interface MlResultStatusSectionProps {
  status: TournamentResultStatusEnum;
  tid: Id<'tournaments'>;
  winnerData: {
    isWinner: boolean;
    rewardAmount?: number;
    rewardMoneyType?: MoneyTypeEnum;
  };
}

export default function MlResultStatusSection({
  status,
  winnerData,
  tid,
}: MlResultStatusSectionProps) {
  return (
    <>
      {status === TournamentResultStatusEnum.ONGOING && (
        <div className="text-[#B4A38A] flex justify-center items-center mx-auto flex-wrap">
          <span>IN</span>
          &nbsp;
          <span>PROGRESS</span>
        </div>
      )}
      {(status === TournamentResultStatusEnum.NON_COLLECTED ||
        status === TournamentResultStatusEnum.COLLECTED) && (
        <div
          className={clsx('flex justify-center items-center', {
            'flex-col': !winnerData.isWinner,
            'mb-auto':
              winnerData.isWinner &&
              status === TournamentResultStatusEnum.NON_COLLECTED,
          })}
        >
          {winnerData.isWinner ? (
            <div className="flex flex-col justify-center items-center relative">
              <Button
                isIconOnly
                disableRipple
                radius="full"
                variant="bordered"
                size="lg"
                className="bg-[#DA8650] font-extrabold text-white text-xl h-16 w-16 sm:h-20 sm:w-20 relative"
              >
                <div className="flex gap-1 pt-2 relative justify-center">
                  {winnerData.rewardMoneyType === MoneyTypeEnum.CASH ? (
                    <>{`$${winnerData.rewardAmount}`}</>
                  ) : (
                    <>
                      <span className="w-6">
                        <AtDiamondIcon />
                      </span>
                      <span>{winnerData.rewardAmount}</span>
                    </>
                  )}
                </div>
              </Button>
              {status === TournamentResultStatusEnum.NON_COLLECTED && (
                <Button
                  size="sm"
                  className="-top-1.5 text-white font-bold"
                  color="success"
                  onPress={async () => {
                    const reward = await collectOneTournamentReward(tid);
                    if (reward?.rewardType === MoneyTypeEnum.CASH) {
                      setCollectType(CollectTypeEnum.CASH);
                      openShowCollectAnimationLayer();
                    } else if (reward?.rewardType === MoneyTypeEnum.CRYSTALS) {
                      setCollectType(CollectTypeEnum.CRYSTALS);
                      openShowCollectAnimationLayer();
                    }
                  }}
                >
                  Collect
                </Button>
              )}
            </div>
          ) : (
            <>
              <span className="">KEEP GOING,</span>
              <span className="">TRY AGAIN</span>
            </>
          )}
          {winnerData.isWinner && (
            <span className="absolute -top-[1.8rem] w-[90px] flex justify-center items-center sm:w-28 sm:-top-[2.3rem]">
              <AtWinnerBannerIcon />
              <span className="absolute text-white text-sm font-semibold">
                Won:
              </span>
            </span>
          )}
        </div>
      )}
    </>
  );
}
