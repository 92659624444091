import { convex } from '@/utils/convex';
import { userStore } from '@/zustand/userStore';
import { api } from '@c/_generated/api';

export async function createUser() {
  const sessionId = userStore.getState().sessionId || '';

  return await convex.mutation(api.user.functions.createUserMutation, {
    sessionId,
  });
}
