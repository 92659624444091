import {
  gameCreatedTime,
  gameIns,
  gameSubmissionTime,
} from '@/zustand/gameStore';
import _ from 'lodash';
import { TActionRes } from '../../../types/game';
import { ActionEnum } from '../../../enums/game';
import {
  handleDeckBaseClick,
  handleDeckLeftTopClick,
  handleDeckLeftToTableau,
  handleDeckRightToDeckLeft,
  handleFoundationToTableau,
  handleTableauCardToFoundation,
  handleTtoT,
} from '@c/game/utils/core';
import { currentAction, replayStore } from '@/zustand/replayStore';
import { EventBus } from '@/utils/eventBus';
import {
  CARD_DRAG_END,
  DECK_LEFT_TOP_CLICK,
  DECK_RIGHT_CLICK,
  STOP_REPLAY,
  TABLEAU_CLICK,
} from '../utils/eventNames';
import { GAME_DURATION } from '@c/utils/constants';

const actionMapper: Partial<Record<ActionEnum, string>> = {
  [ActionEnum.T_TO_T]: CARD_DRAG_END,
  [ActionEnum.F_TO_T]: CARD_DRAG_END,
  [ActionEnum.T_TO_F]: TABLEAU_CLICK,
  [ActionEnum.DECK_RIGHT_TO_LEFT]: DECK_RIGHT_CLICK,
  [ActionEnum.DECK_LEFT_TO_TABLEAU]: CARD_DRAG_END,
  [ActionEnum.DECK_LEFT_TO_FOUNDATION]: DECK_LEFT_TOP_CLICK,
  [ActionEnum.DECK_BASE_CLICK]: ActionEnum.DECK_BASE_CLICK,
};

export function replayActionsHandler(): TActionRes {
  const game = _.cloneDeep(gameIns());
  if (!game) {
    return {
      game,
      valid: false,
    };
  }

  const action = currentAction();

  if (action.actionType === ActionEnum.T_TO_F) {
    return handleTableauCardToFoundation(game, action);
  }

  if (action.actionType === ActionEnum.T_TO_T) {
    return handleTtoT(game, action);
  }

  if (action.actionType === ActionEnum.DECK_RIGHT_TO_LEFT) {
    return handleDeckRightToDeckLeft(game);
  }

  if (action.actionType === ActionEnum.DECK_BASE_CLICK) {
    return handleDeckBaseClick(game);
  }

  if (action.actionType === ActionEnum.DECK_LEFT_TO_FOUNDATION) {
    return handleDeckLeftTopClick(game, action);
  }

  if (action.actionType === ActionEnum.F_TO_T) {
    return handleFoundationToTableau(game, action);
  }

  if (action.actionType === ActionEnum.DECK_LEFT_TO_TABLEAU) {
    return handleDeckLeftToTableau(game, action);
  }

  return {
    game,
    valid: false,
  };
}

export function replayActionsEmitter(): void {
  const actions = replayStore.getState().actions;
  if (actions.length === 0) return;
  const interval = 1000;
  let i = 0;
  let timeStartAt = 0;
  const gameStartTime = gameCreatedTime();
  if (!gameStartTime) return;

  const timer = setInterval(() => {
    const action = actions[i];
    if (!action) {
      return;
    }
    const { actionType, card, timestamp } = action ?? {};
    if (!timestamp) return;
    const deltaTime = timestamp - gameStartTime;

    if (deltaTime >= timeStartAt) {
      const mappedAction = actionMapper[actionType as ActionEnum];
      if (mappedAction) {
        replayStore.setState({ currentIndex: i });
        EventBus.emit(mappedAction, card);
        i++;
        timeStartAt += interval;
      } else {
        console.error('replayActionsEmitter: action not found!');
      }
    }
  }, interval);

  const deltaTime = actualGameDuration();

  setTimeout(() => {
    clearInterval(timer);
    EventBus.emit(STOP_REPLAY);
  }, deltaTime);
}

export function actualGameDuration(): number {
  const submissionTime = gameSubmissionTime();
  const gameCreated = gameCreatedTime();
  if (!submissionTime || !gameCreated) return GAME_DURATION;
  return submissionTime - gameCreated;
}
