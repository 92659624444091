import { motion } from 'framer-motion';
import { TUINavSVGProps } from '../../../../types/uiNav';

export default function AtHomeIcon({
  width = 30,
  height = 30,
  fill = '#FAF5D9',
  scale = 0.9,
  animate = { scale: [0.5, scale] },
  transition = { duration: 0.3, ease: 'easeInOut' },
  play = false,
}: TUINavSVGProps) {
  return (
    <div className="flex flex-col items-center relative">
      <motion.svg
        fill={fill}
        width={width}
        height={height}
        viewBox="0 0 32 32"
        enableBackground="new 0 0 32 32"
        id="Glyph"
        version="1.1"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        scale={scale}
        animate={
          play
            ? animate
            : {
                scale,
              }
        }
        transition={transition}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M30.854,16.548C30.523,17.43,29.703,18,28.764,18H28v11c0,0.552-0.448,1-1,1h-6v-7c0-2.757-2.243-5-5-5 s-5,2.243-5,5v7H5c-0.552,0-1-0.448-1-1V18H3.235c-0.939,0-1.759-0.569-2.09-1.451c-0.331-0.882-0.088-1.852,0.62-2.47L13.444,3.019 c1.434-1.357,3.679-1.357,5.112,0l11.707,11.086C30.941,14.696,31.185,15.666,30.854,16.548z"
            id="XMLID_219_"
          ></path>
        </g>
      </motion.svg>
      {play && <span className="text-white text-xs -mt-1">Lobby</span>}
    </div>
  );
}
