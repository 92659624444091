import _ from 'lodash';
import { AreaEnum, SuitEnum } from '../../../enums/game';
import {
  TActionRes,
  TVActionPayload,
  TVCard,
  TVGame,
} from '../../../types/game';

// action handlers
export function handleDeckBaseClick(game: TVGame): TActionRes {
  // 0. no cards in deck right
  if (game.cards.deck[1].length !== 0) {
    return { game, valid: false };
  }

  // deep copy the game object
  game.cards.deck[1] = _.cloneDeep(game.cards.deck[0]).reverse();
  game.cards.deck[1].forEach((card) => {
    card.hide = true;
    card.area = AreaEnum.DeckRight;
  });
  game.cards.deck[0] = [];

  if (game.score - 20 >= 0) {
    game.score -= 20;
  }

  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function handleDeckRightToDeckLeft(game: TVGame): TActionRes {
  const { deck } = game.cards;

  // 0. no cards in deck right
  if (deck[1].length === 0) {
    return { game, valid: false };
  }

  const startIndex = deck[1].length - 3 > 0 ? deck[1].length - 3 : 0;
  const toRemove = deck[1].splice(startIndex, 3);
  // update toRemove info
  for (let i = 0; i < toRemove.length; i++) {
    toRemove[i].area = AreaEnum.DeckLeft;
    toRemove[i].hide = false;
  }

  deck[0].push(...toRemove.reverse());
  // update deck[0] row
  for (let i = 0; i < deck[0].length; i++) {
    deck[0][i].row = i;
  }

  // 1. return the game
  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function handleDeckLeftToTableau(
  game: TVGame,
  action: TVActionPayload,
): TActionRes {
  const { card, target } = action;

  // 0. no card or target
  if (!card || !target) return { game, valid: false };

  // if the target column is empty, only the king can be added
  if (game.cards.tableau[target.col].length === 0) {
    if (card.value !== '13') return { game, valid: false };
  } else {
    // if not empty, check if the card can be attached to the last card
    const canAttach = attachable(
      card,
      game.cards.tableau[target.col][game.cards.tableau[target.col].length - 1],
    );
    if (!canAttach) return { game, valid: false };
  }

  const removedCard = game.cards.deck[0].splice(game.cards.deck[0].length - 1);

  removedCard[0].hide = false;
  removedCard[0].area = AreaEnum.Tableau;
  removedCard[0].col = target.col;
  removedCard[0].row = game.cards.tableau[target.col].length;

  game.cards.tableau[target.col].push(removedCard[0]);

  game.score += 20;

  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function handleDeckLeftTopClick(
  game: TVGame,
  action: TVActionPayload,
): TActionRes {
  const { deck } = game.cards;
  const targetArea = action.target?.area;
  const targetCol = action.target?.col;

  const invalidTargetCol =
    targetCol === undefined ||
    targetCol === null ||
    targetCol < 0 ||
    targetCol >= game.cards.foundation.length;

  if (!targetArea || invalidTargetCol) {
    return { game, valid: false };
  }

  // 0. no cards in deck left
  if (deck[0].length === 0) {
    return { game, valid: false };
  }

  // type & value check
  const canMove = canMoveToFoundation(
    deck[0][deck[0].length - 1],
    game.cards.foundation,
    targetCol,
  );
  if (!canMove) return { game, valid: false };

  const toRemove = deck[0].splice(deck[0].length - 1);
  toRemove[0].hide = false;
  toRemove[0].area = AreaEnum.Foundation;
  toRemove[0].col = targetCol;
  toRemove[0].row = game.cards.foundation[targetCol].length;

  game.cards.foundation[targetCol].push(toRemove[0]);
  game.score += 100;

  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function handleTtoT(game: TVGame, action: TVActionPayload): TActionRes {
  const { card, target } = action;
  // no card or target
  if (!card || !target) return { game, valid: false };
  const { tableau } = game.cards;
  const targetCard = tableau[target.col][tableau[target.col].length - 1];

  // 1. no actual card || actual card is hidden || wrong target column || wrong card area
  if (card.hide) return { game, valid: false };
  const wrongTargetCol = target.col < 0 || target.col >= tableau.length;
  if (wrongTargetCol) return { game, valid: false };
  const wrongCardArea = card.area !== AreaEnum.Tableau;
  if (wrongCardArea) return { game, valid: false };

  const { row } = card;

  // 2. target column is empty
  const emptyTargetCol = !targetCard;
  if (emptyTargetCol) {
    const removeCards = tableau[card.col].splice(row);
    const firstCard = removeCards[0];
    // only the value of the first card is 13, it can be moved to an empty column
    if (firstCard.value !== '13') return { game, valid: false };

    tableau[target.col].push(...removeCards);

    // show the last card in the actual column
    const lastCardExist = showLastCard(card.col, tableau);
    if (lastCardExist) game.score += 20;

    // update removed cards row, col
    for (let i = 0; i < tableau[target.col].length; i++) {
      tableau[target.col][i].col = target.col;
      tableau[target.col][i].row = i;
    }

    return {
      game: {
        ...game,
        canUndo: true,
      },
      valid: true,
    };
  } else {
    const canAttach = attachable(card, targetCard);
    // 3. can attach to the target card
    if (canAttach) {
      const removeCards = tableau[card.col].splice(row);
      tableau[target.col].push(...removeCards);

      // show the last card in the actual column
      const lastCardExist = showLastCard(card.col, tableau);
      if (lastCardExist) game.score += 20;

      // update removed cards row, col
      for (let i = 0; i < tableau[target.col].length; i++) {
        tableau[target.col][i].col = target.col;
        tableau[target.col][i].row = i;
      }

      return {
        game: {
          ...game,
          canUndo: true,
        },
        valid: true,
      };
    }
    // 4. can't attach to the target card
    return {
      game,
      valid: false,
    };
  }
}

export function handleTableauCardToFoundation(
  game: TVGame,
  action: TVActionPayload,
): TActionRes {
  // action sanity check
  const { card, target } = action;
  if (!card || !target) return { game, valid: false };

  // type & value check
  const canMove = canMoveToFoundation(card, game.cards.foundation, target.col);
  if (!canMove) return { game, valid: false };
  // remove the card from tableau
  const toRemove = game.cards.tableau[card.col].splice(card.row);

  // add the card to foundation
  game.cards.foundation[target.col].push(...toRemove);

  // update the card info
  toRemove[0].area = AreaEnum.Foundation;
  toRemove[0].col = target.col;
  toRemove[0].row = game.cards.foundation[target.col].length - 1;

  // show the last card in the actual column if there is any
  const lastCardExist = showLastCard(card.col, game.cards.tableau);
  if (lastCardExist) game.score += 20;

  game.score += 120;

  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function canMoveToFoundation(
  card: TVCard,
  foundation: TVCard[][],
  targetCol: number,
): boolean {
  // if the foundation is empty, only the ace can be added
  const allFoundationEmpty = foundation.every((col) => col.length === 0);
  if (allFoundationEmpty) {
    if (card.value !== '1') return false;
  } else {
    let typeExist = false;
    for (const f of foundation) {
      if (f.length > 0 && f[0].type === card.type) {
        typeExist = true;
        break;
      }
    }

    // if type exist in the foundation, only the next card can be added
    if (typeExist) {
      const lastCard = foundation[targetCol][foundation[targetCol].length - 1];
      if (!lastCard) return false;
      if (parseInt(card.value) !== parseInt(lastCard.value) + 1) return false;
    }

    // if type is not in the foundation, only the ace can be added
    if (!typeExist) {
      if (card.value !== '1') return false;
    }
  }

  return true;
}

export function handleFoundationToTableau(
  game: TVGame,
  action: TVActionPayload,
): TActionRes {
  // sanity check
  const { card, target } = action;
  if (!card || !target) return { game, valid: false };

  // remove the card from foundation
  const toRemove = game.cards.foundation[card.col].splice(
    game.cards.foundation[card.col].length - 1,
  );

  // if the target column is empty, only the king can be added
  if (game.cards.tableau[target.col].length === 0) {
    if (toRemove[0].value !== '13') return { game, valid: false };
  }

  // check attachable
  const canAttach = attachable(
    toRemove[0],
    game.cards.tableau[target.col][game.cards.tableau[target.col].length - 1],
  );
  if (!canAttach) return { game, valid: false };

  // add the card to tableau
  toRemove[0].area = AreaEnum.Tableau;
  toRemove[0].col = target.col;
  toRemove[0].row = game.cards.tableau[target.col].length;
  game.cards.tableau[target.col].push(toRemove[0]);

  if (game.score - 120 > 0) {
    game.score -= 120;
  } else {
    game.score = 0;
  }

  return {
    game: {
      ...game,
      canUndo: true,
    },
    valid: true,
  };
}

export function attachable(actualCard: TVCard, targetCard: TVCard): boolean {
  const red = [SuitEnum.Hearts, SuitEnum.Diamonds] as string[];

  const actualColor = red.includes(actualCard.type) ? 'red' : 'black';
  const targetColor = red.includes(targetCard.type) ? 'red' : 'black';

  if (actualColor === targetColor) return false;

  // actual card value is one less than the target card value
  if (parseInt(actualCard.value) + 1 !== parseInt(targetCard.value))
    return false;

  return true;
}

export function showLastCard(col: number, tableau: TVCard[][]): boolean {
  const lastCard = tableau[col][tableau[col].length - 1];
  const addScore = lastCard?.hide;
  if (lastCard) lastCard.hide = false;

  return addScore;
}

// red or black
export function isDiffType(card1: TVCard, card2: TVCard): boolean {
  const red = [SuitEnum.Hearts, SuitEnum.Diamonds] as string[];
  const card1Color = red.includes(card1.type) ? 'red' : 'black';
  const card2Color = red.includes(card2.type) ? 'red' : 'black';

  return card1Color !== card2Color;
}

export function checkGameSolvedAfterAction(game: TVGame) {
  const deckLeft = game.cards.deck[0];
  const deckRight = game.cards.deck[1];

  if (deckLeft.length !== 0 || deckRight.length !== 0) {
    return false;
  }

  let result = true;
  const tableau = game.cards.tableau;
  for (let i = 0; i < tableau.length; i++) {
    for (let j = 0; j < tableau[i].length - 1; j++) {
      const currentCard = tableau[i][j];
      const nextCard = tableau[i][j + 1];

      if (!currentCard || !nextCard) {
        continue;
      }

      if (parseInt(nextCard.value) - parseInt(currentCard.value) !== -1) {
        result = false;
        break;
      }

      if (!isDiffType(currentCard, nextCard)) {
        result = false;
        break;
      }
    }
  }

  return result;
}

export function checkGameSolvedAfterGameOver(game: TVGame): boolean {
  const { foundation } = game.cards;
  const allFoundationFull = foundation.every((col) => col.length === 13);

  if (allFoundationFull) {
    return true;
  }

  return false;
}
