import { Card, CardBody } from '@nextui-org/react';
import AtGroupIcon from '../atoms/icons/AtGroupIcon';
import AtLeaderboardIcon from '../atoms/icons/AtLeaderboardIcon';
import AtDateIcon from '../atoms/icons/AtDateIcon';
import MlResultCardRank from './MlResultCardRank';
import { TTournamentResult } from '../../../types/tournament';
import { tournamentTypesStore } from '@/zustand/tournamentTypesStore';
import MlResultStatusSection from './MlResultStatusSection';
import {
  MoneyTypeEnum,
  TournamentResultStatusEnum,
} from '../../../enums/tournament';
import { useMemo } from 'react';
import { showResultStatusModal } from '@/zustand/uiManagementStore';

export interface IMlResultCardProps {
  data: TTournamentResult;
  status: TournamentResultStatusEnum;
}

export default function MlResultCard({ data, status }: IMlResultCardProps) {
  const tournamentTypesData = tournamentTypesStore((state) => state.data);

  const currentType = tournamentTypesData?.find(
    (t) => t.type === data.tournament,
  );

  const checkIfIsWinner = useMemo((): {
    isWinner: boolean;
    rewardAmount?: number;
    rewardMoneyType?: MoneyTypeEnum;
  } => {
    if (!currentType?.rewardMoneyAmount)
      return {
        isWinner: false,
      };

    if (data.rank < currentType?.rewardMoneyAmount.length) {
      return {
        isWinner: true,
        rewardAmount: currentType.rewardMoneyAmount[data.rank],
        rewardMoneyType: currentType.rewardMoneyType as MoneyTypeEnum,
      };
    }

    return {
      isWinner: false,
    };
  }, [currentType?.rewardMoneyAmount, currentType?.rewardMoneyType, data.rank]);

  const formattedDate = useMemo(() => {
    if (data.timestamps) {
      const date = new Date(data.timestamps);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    }
  }, [data.timestamps]);

  if (!data || !currentType) return;

  return (
    <>
      <Card>
        <CardBody
          onClick={() => {
            showResultStatusModal(data.tid);
          }}
          className="p-1 bg-[#FAEAD1] text-white hover:cursor-pointer"
        >
          {/* wrapper div */}
          <div className="flex">
            {/* left div */}
            <div className="bg-[#318770] p-2 sm:p-4 rounded-tl-lg rounded-bl-lg flex justify-center items-center">
              <div className="w-8 sm:w-14 flex justify-center items-center">
                <MlResultCardRank rank={data.rank} />
              </div>
            </div>
            {/* center div */}
            <div className="ml-2 sm:ml-10 flex flex-col gap-0.5">
              <div className="text-[#8F5F28] text-lg sm:text-2xl font-semibold">
                {currentType.title}
              </div>
              <div className="flex items-center gap-1 sm:gap-6 flex-wrap">
                {/* players */}
                <div className="flex sm:gap-2">
                  <div className="sm:w-6 hidden sm:block">
                    <AtGroupIcon />
                  </div>
                  <div className="bg-[#D6C393] rounded-full p-1 sm:px-4 py-0  justify-center items-center self-center text-[#8F5F28]">
                    {`${currentType.size} Players`}
                  </div>
                </div>
                <div className="text-[#8F5F28] flex items-center gap-1">
                  <span className="w-6 hidden sm:block">
                    <AtDateIcon />
                  </span>
                  <span>{formattedDate}</span>
                </div>
              </div>
              {/* leader board */}
              <div className="text-[#8F5F28] flex items-center">
                <span className="w-5">
                  <AtLeaderboardIcon />
                </span>
                <span className="font-medium">Leaderboard</span>
              </div>
            </div>
            {/* right div */}
            <div className="ml-1 sm:ml-20 bg-[#F1DEAF] px-1 flex flex-col justify-center items-center text-[#B4A38A] flex-1">
              <MlResultStatusSection
                status={status}
                tid={data.tid}
                winnerData={checkIfIsWinner}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
