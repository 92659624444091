export const COMBAT = 'combatScene';

import Phaser from 'phaser';
import { CardSprite } from '@/game/components/cardSprite';
import { BASE, CARDS } from '@/game/scenes/preloadScene';
import {
  BASE_CARD_ALPHA,
  CARD_AREA_PADDING_X,
  CARD_COL_HIDE_MARGIN,
  CARD_COL_MARGIN,
  CARD_FLIP_DURATION,
  CARD_HEIGHT,
  CARD_MOVING_DURATION,
  CARD_WIDTH,
  DECK_GAP,
  DECK_LEFT_OFFSET,
  FOUNDATION_Y,
  GAME_WIDTH,
  TABLEAU_Y,
} from '@/game/utils/constants';
import { gameStore } from '@/zustand/gameStore';
import { TVCard, TPos } from '@/../types/game';
import { AreaEnum, ActionEnum } from '@/../enums/game';
import {
  ADD_SCORE,
  CARD_DRAG,
  CARD_DRAG_END,
  CARD_DRAG_START,
  DECK_BASE_CLICK,
  DECK_LEFT_TOP_CLICK,
  DECK_RIGHT_CLICK,
  DISABLE_INPUT,
  ENABLE_INPUT,
  GAME_OVER,
  GAME_SOLVED,
  MINUS_SCORE,
  PAUSE_GAME,
  REMOVE_LOADING_FROM_GAME,
  RESUME_GAME,
  START_REPLAY,
  STOP_REPLAY,
  TABLEAU_CLICK,
  UNDO_BTN_CLICK,
} from '@/game/utils/eventNames';
import { getGameById } from '@/services/game';
import { gameActionHandler } from '@/game/core/actions';
import {
  interactionStore,
  isInteractive,
} from '../../zustand/interactionStore';
import { timerCalc } from '@/hooks/useTimer';
import { EventBus } from '@/utils/eventBus';
import { uiManagementStore } from '@/zustand/uiManagementStore';
import {
  clearReplayStore,
  currentAction,
  isReplaying,
  replayEndTime,
  replayStore,
} from '@/zustand/replayStore';
import { replayActionsEmitter } from '../core/replayActions';
import { GAME_DURATION } from '@c/utils/constants';
import gsap from 'gsap';
import { joinTournament } from '@/services/tournament';
import { tournamentTypesStore } from '@/zustand/tournamentTypesStore';

export class CombatScene extends Phaser.Scene {
  tableauCardSprites: CardSprite[][] = [];
  foundationCardSprites: CardSprite[][] = [];
  deckCardSprites: CardSprite[][] = [[], []];
  uiContainer: Phaser.GameObjects.Container | null = null;
  graphics: Phaser.GameObjects.Graphics | null = null;
  score: Phaser.GameObjects.Text | null = null;

  foundationPlaceholders: Phaser.GameObjects.Image[] = [];
  deckRightBase: Phaser.GameObjects.Sprite | null = null;

  constructor() {
    super(COMBAT);
  }

  async create() {
    await this.initGame();
    this.eventsListeners();

    interactionStore.subscribe((state) => {
      this.input.enabled = state.canInteract;
    });
  }

  async initGame() {
    await this.createGame();

    this.createTableau();
    this.createDeck();
    this.createFoundation();

    this.createUI();
  }

  createUI() {
    this.drawBlackBg(true);
    this.drawBlackBg(false);

    this.uiContainer = this.add.container(0, 0);

    const textY = 10;

    const timeLabel = this.add
      .text(70, textY, 'Time', {
        fontSize: '22px',
        color: '#fff',
        fontFamily: 'Play',
        align: 'center',
      })
      .setAlpha(0.8);

    const scoreLabel = this.add
      .text(510, textY, 'Score', {
        fontSize: '22px',
        color: '#fff',
        fontFamily: 'Play',
        align: 'center',
      })
      .setAlpha(0.8);
    timeLabel.setOrigin(0, 0);

    this.uiContainer.add(timeLabel);
    this.uiContainer.add(scoreLabel);

    this.addTimer();
    this.addScore();
  }

  eventsListeners() {
    this.onTableauCardClick();
    this.onDeckRightClick();
    this.onDeckLeftTopCardClick();

    this.onDragStart();
    this.onCardDrag();
    this.onCardDragEnd();
    this.onUndoAction();
    this.onGameOver();
    this.onToggleInput();
    this.onPauseGame();
    this.onRemoveLoadingFromGame();
    this.onStartReplay();
    this.onStopReplay();
    this.onScoreChange();
    this.onGameSolved();
  }

  async createGame() {
    let game;

    if (isReplaying()) {
      // disable input
      interactionStore.setState({ canInteract: false });

      game = await getGameById();
      gameStore.setState({
        game: game.game,
        createdTime: game.createdTime,
        submissionTime: game.submissionTime,
      });
      replayStore.setState({
        actions: game.actions,
      });
    } else {
      try {
        const currentTournamentType =
          tournamentTypesStore.getState().currentType;
        game = await joinTournament(currentTournamentType || undefined);
        // TODO: set current tournament id to store
      } catch (error) {
        console.error({ error });
      }
    }

    // TODO: handle error
    if (!game) {
      throw new Error('Failed to create game');
    }
    // init game store
    gameStore.setState({
      gameId: game.gameId,
      game: game.game,
    });
  }

  // ======================================== UI ========================================
  drawBlackBg(isLeft: boolean) {
    if (!this.graphics) {
      this.graphics = this.add.graphics();
    }

    const width = 100;
    let x = 0;
    const y = 40;

    const leftX = 50;
    const rightX = GAME_WIDTH - 50 - width;

    if (isLeft) {
      x = leftX;
    } else {
      x = rightX;
    }

    // 设置填充样式
    this.graphics.fillStyle(0x000000, 0.2);
    // 绘制填充的圆角矩形
    this.graphics.fillRoundedRect(x, y, width, 35, 14);
  }

  addTimer() {
    const timer = this.add.text(61, 44, '00 : 00', {
      fontSize: '26px',
      color: '#fff',
      fontFamily: 'Arial',
      align: 'center',
      fontStyle: 'bold',
    });

    this.time.addEvent({
      delay: 500,
      callback: () => {
        let endTime = gameStore.getState().game?.endTimestamp || 0;
        if (isReplaying()) {
          if (replayEndTime() === 0) {
            replayStore.setState({
              replayEndTime: GAME_DURATION + Date.now(),
            });
          }
          endTime = replayEndTime();
        }

        const { minutes, seconds, startGame } = timerCalc(
          endTime,
          isReplaying(),
        );

        timer.setText(
          `${minutes.toString().padStart(2, '0')} : ${seconds
            .toString()
            .padStart(2, '0')}`,
        );

        if (startGame) {
          EventBus.emit(REMOVE_LOADING_FROM_GAME);
          if (isReplaying()) {
            EventBus.emit(START_REPLAY);
          }
        }
      },
      callbackScope: this,
      loop: true,
    });

    this.uiContainer?.add(timer);
    this.uiContainer?.bringToTop(timer);
  }

  addScore() {
    this.score = this.add
      .text(538, 44, '0', {
        fontSize: '26px',
        color: '#fff',
        fontFamily: 'Arial',
        align: 'center',
        fontStyle: 'bold',
      })
      .setOrigin(0.5, 0);

    this.uiContainer?.add(this.score);

    this.updateScore();
  }

  updateScore() {
    const scoreNum = gameStore.getState().game?.score;
    this.score?.setText(scoreNum?.toString() || '0');
  }

  // ======================================== Areas ========================================
  createTableau() {
    // create base cards
    for (let i = 0; i < 7; i++) {
      // add base card
      const placeholder = this.add
        .image(
          this.calculateColX(i, CARD_WIDTH),
          TABLEAU_Y,
          BASE,
          `star-base.png`,
        )
        .setOrigin(0, 0);
      placeholder.setDisplaySize(CARD_WIDTH, CARD_HEIGHT);
      placeholder.setAlpha(BASE_CARD_ALPHA);
      //
    }

    const gameCards = gameStore.getState().game?.cards;
    let cards: TVCard[][] = [];
    if (gameCards) {
      cards = gameCards.tableau;
    }

    for (let i = 0; i < 7; i++) {
      this.tableauCardSprites[i] = [];
      const colCards = cards[i];
      for (let j = 0; j < colCards.length; j++) {
        const current = colCards[j];
        const createdCard = this.createTableauCardSprite(current, i, j);
        this.tableauCardSprites[i].push(createdCard);
      }
    }
  }

  createFoundation() {
    // add 4 placeholders for foundation
    for (let i = 0; i < 4; i++) {
      const placeholder = this.add
        .image(
          this.calculateColX(i, CARD_WIDTH),
          FOUNDATION_Y,
          BASE,
          `card-base.png`,
        )
        .setOrigin(0, 0);
      placeholder.setDisplaySize(CARD_WIDTH, CARD_HEIGHT);
      placeholder.setAlpha(BASE_CARD_ALPHA);
      this.foundationPlaceholders.push(placeholder);
    }

    // add foundation cards
    const gameCards = gameStore.getState().game?.cards;
    const foundationCards = gameCards?.foundation;
    if (!foundationCards) return;

    for (let i = 0; i < 4; i++) {
      this.foundationCardSprites[i] = [];
      const colCards = foundationCards[i];
      for (let j = 0; j < colCards.length; j++) {
        const current = colCards[j];
        const createdCard = new CardSprite(
          this,
          this.calculateColX(i, CARD_WIDTH),
          FOUNDATION_Y,
          CARDS,
          `${current.type}-${current.value}.png`,
          current,
        );
        this.foundationCardSprites[i].push(createdCard);
        createdCard.updatePosition(
          this.calculateColX(i, createdCard.displayWidth),
          FOUNDATION_Y,
        );
      }
    }
  }

  createDeck() {
    // add base card
    const placeholder = this.add
      .sprite(
        this.calculateColX(6, CARD_WIDTH),
        FOUNDATION_Y,
        BASE,
        `star-base.png`,
      )
      .setOrigin(0, 0)
      .setName('deckRightBase');
    placeholder.setDisplaySize(CARD_WIDTH, CARD_HEIGHT);
    placeholder.setAlpha(BASE_CARD_ALPHA);
    placeholder.setInteractive();
    this.deckRightBase = placeholder;

    this.displayDeckLeft();
    this.displayDeckRight();

    this.deckRightBaseClick();
  }

  updateDeckCardSpritesWhenClickRight() {
    const positions = this.calculateDeckLeftPositions();

    // move bottom cards to left
    this.deckCardSprites[0].forEach((card) => {
      card.x = positions[0].x;
      card.y = positions[0].y;

      card.updatePosition(positions[0].x, positions[0].y);
    });

    // move card sprites
    const firstThreeCardSpritesInRightDeck = this.deckCardSprites[1].splice(-3);
    this.deckCardSprites[0].push(...firstThreeCardSpritesInRightDeck);

    // update deck cards info
    const game = gameStore.getState().game;
    if (!game) return;
    const deckLeft = game.cards.deck[0];
    const deckRight = game.cards.deck[1];
    for (let i = 0; i < deckLeft.length; i++) {
      const card = this.deckCardSprites[0][i];
      card.updateCardInfo(deckLeft[i]);
    }
    for (let i = 0; i < deckRight.length; i++) {
      const card = this.deckCardSprites[1][i];
      card.updateCardInfo(deckRight[i]);
    }

    const tl = gsap.timeline({
      onComplete: () => {
        this.reOrgDeckLeftCards();
        interactionStore.setState({ canInteract: true });
      },
    });

    firstThreeCardSpritesInRightDeck.forEach((card, i) => {
      const pos = positions[i];
      card.updatePosition(pos.x, pos.y);

      const cardTl = gsap.timeline();
      cardTl
        .add(() => {
          this.children.bringToTop(card);
        })
        .add(this.flipDeckCardTweens(card, true), 0)
        .add(
          this.moveCardSpritePositionTweens(card, pos, true),
          CARD_FLIP_DURATION / 1000,
        );

      tl.add(cardTl, i * 0.1);
    });
  }

  displayDeckLeft() {
    // display the left part of deck
    const game = gameStore.getState().game;
    if (!game) return;
    const deckLeft = game.cards.deck[0];
    let showCards: TVCard[] = [];
    if (deckLeft.length < 3) {
      showCards = deckLeft;
    } else {
      showCards = deckLeft.slice(deckLeft.length - 3);
    }
    const firstShowCardIndex = deckLeft.length - showCards.length;
    const baseX = this.calculateColX(5, CARD_WIDTH);

    // add bottom cards
    for (let i = 0; i < firstShowCardIndex; i++) {
      const card = deckLeft[i];
      const createdCard = new CardSprite(
        this,
        0,
        0,
        CARDS,
        `${card.type}-${card.value}.png`,
        card,
      );
      this.deckCardSprites[0].push(createdCard);

      createdCard.x = baseX - 2 * DECK_LEFT_OFFSET - DECK_GAP;
      createdCard.y = FOUNDATION_Y;
      createdCard.updatePosition(
        baseX - 2 * DECK_LEFT_OFFSET - DECK_GAP,
        FOUNDATION_Y,
      );
    }

    // add top cards
    for (let i = 0; i < showCards.length; i++) {
      const modOfThree = i % 3;
      const card = showCards[i];

      const createdCard = new CardSprite(
        this,
        0,
        0,
        CARDS,
        `${card.type}-${card.value}.png`,
        card,
      );
      this.deckCardSprites[0].push(createdCard);

      const factor = Math.abs(modOfThree - 2);

      createdCard.x = baseX - factor * DECK_LEFT_OFFSET - DECK_GAP;
      createdCard.y = FOUNDATION_Y;
      createdCard.updatePosition(
        baseX - factor * DECK_LEFT_OFFSET - DECK_GAP,
        FOUNDATION_Y,
      );
    }
  }

  displayDeckRight() {
    // display the right part of deck
    const game = gameStore.getState().game;
    if (!game) return;
    const deckRight = game.cards.deck[1];

    for (let i = 0; i < deckRight.length; i++) {
      const current = deckRight[i];
      const createdCard = new CardSprite(
        this,
        0,
        0,
        CARDS,
        'cover.png',
        current,
      );
      this.deckCardSprites[1].push(createdCard);

      createdCard.x = this.calculateColX(6, CARD_WIDTH);
      createdCard.y = FOUNDATION_Y;
      createdCard.updatePosition(
        this.calculateColX(6, createdCard.displayWidth),
        FOUNDATION_Y,
      );
    }
  }

  createTableauCardSprite(card: TVCard, col: number, row: number) {
    let cardImage = 'cover.png';

    if (!card.hide && card.type && card.value) {
      cardImage = `${card.type}-${card.value}.png`;
    }

    const createdCard = new CardSprite(this, 0, 0, CARDS, cardImage, card);
    createdCard.x =
      CARD_AREA_PADDING_X +
      (createdCard.displayWidth +
        this.calculateCardsGap(createdCard.displayWidth)) *
        col;

    if (row !== 0) {
      const cards = this.tableauCardSprites[col];
      const lastCard = cards[row - 1];
      createdCard.y = lastCard.info.hide
        ? lastCard.y + CARD_COL_HIDE_MARGIN
        : lastCard.y + CARD_COL_MARGIN;
    } else {
      createdCard.y = TABLEAU_Y;
    }

    createdCard.updatePosition(createdCard.x, createdCard.y);

    return createdCard;
  }

  onTableauCardClick() {
    EventBus.on(TABLEAU_CLICK, async (cardInfo: TVCard, fromDrag?: boolean) => {
      const card = this.getCardByInfo(cardInfo);
      if (!card) return;
      this.children.bringToTop(card);
      const targetFoundationIndex = this.getTargetFoundationIndex(card.info);
      // send action
      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;

      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.T_TO_F,
        card: { ...card.info },
        target: { area: AreaEnum.Foundation, col: targetFoundationIndex },
      });

      if (actionRes.valid) {
        // move card to foundation
        const { x, y } = this.foundationPlaceholders[targetFoundationIndex];
        card.updatePosition(x, y);
        this.moveCardSpritePositionTweens(card, card.pos);
        this.tableauCardSpriteMoveToFoundation(
          card.info,
          targetFoundationIndex,
        );
        gameStore.setState({
          game: actionRes.game,
        });
        this.updateFoundationCardSprite();
        this.updateTableauCards();
        this.foundationPlaceholdersTweens();
      } else {
        if (fromDrag) this.moveAllCardsToTheirPositions();
        if (!fromDrag) this.cardYoYo(card);
      }
    });
  }

  onDeckRightClick() {
    EventBus.on(DECK_RIGHT_CLICK, async () => {
      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;

      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.DECK_RIGHT_TO_LEFT,
      });

      if (actionRes.valid) {
        // update game store
        gameStore.setState({
          game: actionRes.game,
        });
        this.updateDeckCardSpritesWhenClickRight();
      }
    });
  }

  onDeckLeftTopCardClick() {
    EventBus.on(
      DECK_LEFT_TOP_CLICK,
      async (cardInfo: TVCard, fromDrag?: boolean) => {
        const card = this.getCardByInfo(cardInfo);
        if (!card) return;
        const targetFoundationIndex = this.getTargetFoundationIndex(card.info);
        // send action
        const game = gameStore.getState().game;
        const gameId = gameStore.getState().gameId;
        if (!game || !gameId) return;
        const actionRes = await gameActionHandler({
          gameId,
          actionType: ActionEnum.DECK_LEFT_TO_FOUNDATION,
          card: { ...card.info },
          target: { area: AreaEnum.Foundation, col: targetFoundationIndex },
        });
        if (actionRes?.valid) {
          // move card to foundation
          const { x, y } = this.foundationPlaceholders[targetFoundationIndex];
          card.updatePosition(x, y);
          this.moveCardSpritePositionTweens(card, card.pos, false, 2);
          // update sprites
          this.deckLeftCardSpriteMoveToFoundation(targetFoundationIndex);
          // update game store
          gameStore.setState({
            game: actionRes.game,
          });
          this.updateFoundationCardSprite();
          this.reOrgDeckLeftCards();
          this.foundationPlaceholdersTweens();
        } else {
          if (fromDrag) {
            this.moveAllCardsToTheirPositions();
          }
          if (!fromDrag) {
            this.cardYoYo(card);
          }
        }
      },
    );
  }

  onDragStart() {
    EventBus.on(CARD_DRAG_START, (cardInfo: TVCard) => {
      const card = this.getCardByInfo(cardInfo);
      if (!card) return;

      this.children.bringToTop(card);

      if (card.info.area === AreaEnum.Tableau) {
        const cardsNeedToMove = this.getAllCardsNeedToMove(
          card.info.col,
          card.info.row,
        );

        for (const card of cardsNeedToMove) {
          this.children.bringToTop(card);
        }
      }
    });
  }

  onCardDrag() {
    EventBus.on(
      CARD_DRAG,
      (
        cardInfo: TVCard,
        offPos: {
          x: number;
          y: number;
        },
      ) => {
        if (
          cardInfo.area === AreaEnum.Tableau &&
          this.isDraggingMultipleCards(cardInfo)
        ) {
          this.moveMultipleCards(cardInfo, offPos);
        }
      },
    );
  }

  onCardDragEnd() {
    EventBus.on(CARD_DRAG_END, (cardInfo: TVCard) => {
      let card = this.getCardByInfo(cardInfo);
      if (!card) return;

      let actionType = this.checkDragendAction(card, {
        x: card.x,
        y: card.y,
      });
      // ================== replay ==================
      if (isReplaying()) {
        actionType = currentAction().actionType;
        const currentCard = currentAction().card;
        if (!currentCard) return;
        card = this.getCardByInfo(currentCard);
        cardInfo = currentAction().card!;
      }
      if (!card) return;
      // ============================================

      if (actionType === ActionEnum.T_TO_T) {
        this.onTtoT(card);
        return;
      }

      if (actionType === ActionEnum.DECK_LEFT_TO_TABLEAU) {
        this.onDeckLeftToTableau(card);
        return;
      }

      if (actionType === ActionEnum.F_TO_T) {
        this.onFoundationToTableau(card);
        return;
      }

      if (actionType === ActionEnum.DECK_LEFT_TO_FOUNDATION) {
        EventBus.emit(DECK_LEFT_TOP_CLICK, cardInfo, true);
        return;
      }

      if (actionType === ActionEnum.T_TO_F) {
        EventBus.emit(TABLEAU_CLICK, cardInfo, true);
        return;
      }

      this.moveAllCardsToTheirPositions();
    });
  }

  onUndoAction() {
    EventBus.on(UNDO_BTN_CLICK, async () => {
      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;

      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.UNDO,
      });

      if (actionRes.valid) {
        // re-organize areas
        gameStore.setState({
          game: actionRes.game,
        });
        this.reOrgAreas();

        interactionStore.setState({ canInteract: true });
      }
    });
  }

  onGameOver() {
    EventBus.on(GAME_OVER, () => {
      this.tableauCardSprites = [];
      this.foundationCardSprites = [];
      this.deckCardSprites = [[], []];
      this.uiContainer?.destroy();
      this.uiContainer = null;
      this.graphics?.destroy();
      this.graphics = null;
      this.score = null;
      this.foundationPlaceholders = [];
      this.deckRightBase = null;

      // event bus off all events
      EventBus.destroy();

      this.children.each((child) => {
        child.destroy();
      });

      this.scene.stop();
    });
  }

  onToggleInput() {
    EventBus.on(DISABLE_INPUT, () => {
      this.input.enabled = false;
    });

    EventBus.on(ENABLE_INPUT, () => {
      this.input.enabled = true;
    });
  }

  onPauseGame() {
    EventBus.on(PAUSE_GAME, () => {
      this.scene.pause();
    });

    EventBus.on(RESUME_GAME, () => {
      this.scene.resume();
    });
  }

  onRemoveLoadingFromGame() {
    EventBus.once(REMOVE_LOADING_FROM_GAME, () => {
      const isLoading = uiManagementStore.getState().isLoading;

      if (!isLoading) return;

      uiManagementStore.setState({ isLoading: false });
    });
  }

  onStartReplay() {
    EventBus.once(START_REPLAY, () => {
      replayActionsEmitter();
    });
  }

  onStopReplay() {
    EventBus.on(STOP_REPLAY, () => {
      // clear replay store
      clearReplayStore();
      this.scene.pause();
    });
  }

  onScoreChange() {
    EventBus.on(ADD_SCORE, (delta: number) => {
      this.updateScoreTweens(delta, true);
    });

    EventBus.on(MINUS_SCORE, (delta: number) => {
      this.updateScoreTweens(delta, false);
    });
  }

  onGameSolved() {
    EventBus.on(GAME_SOLVED, () => {});
  }

  updateScoreTweens(delta: number, isAdd = true) {
    const x = 420;
    const y = 50;
    const text = isAdd ? '+' : '-';
    const targetY = isAdd ? y - 10 : y + 10;
    const color = isAdd ? '#FFD700' : '#FF0000';
    // 创建分数文本
    const score = this.add
      .text(x, y, text + delta, {
        fontSize: '32px',
        color,
        stroke: '#000',
        fontStyle: 'bold',
      })
      .setOrigin(0.5, 0.5);

    // 使用 GSAP 动画文本：缩放、移动和淡出
    gsap.to(score, {
      y: targetY,
      alpha: 0,
      scale: 1.6, // 从 1 缩放到 1.6
      ease: 'cubic.easeOut',
      duration: 1.3, // 持续时间为 1.3 秒
      onComplete: () => {
        score.destroy(); // 动画完成后销毁文本对象
      },
    });
  }

  // minusScoreTweens(delta: number) {}

  isDraggingMultipleCards(info: TVCard): boolean {
    const { col, row } = info;

    if (row < this.tableauCardSprites[col].length - 1) {
      return true;
    }
    return false;
  }

  moveMultipleCards(info: TVCard, offPos: TPos) {
    const { col, row } = info;
    const cardsToMove = this.tableauCardSprites[col].slice(row + 1);

    for (let i = 0; i < cardsToMove.length; i++) {
      const card = cardsToMove[i];
      card.x = offPos.x + card.pos.x;
      card.y = offPos.y + card.pos.y;
    }
  }

  getAllCardsNeedToMove(col: number, row: number) {
    const cards = this.tableauCardSprites[col].slice(row);
    return cards;
  }

  calculateTableauTargetPosition(col: number): TPos {
    const targetX =
      CARD_AREA_PADDING_X +
      col * CARD_WIDTH +
      col * this.calculateCardsGap(CARD_WIDTH);

    const targetIndex = this.tableauCardSprites[col].length;
    const targetCard = this.tableauCardSprites[col][targetIndex - 1];
    let targetY;

    if (targetCard) {
      targetY = targetCard.y + CARD_COL_MARGIN;
    } else {
      targetY = TABLEAU_Y;
    }

    return { x: targetX, y: targetY };
  }

  moveTableauCardsToTableauTargetPosition(card: CardSprite, targetPos: TPos) {
    const cards = this.tableauCardSprites[card.info.col].slice(card.info.row);

    for (let i = 0; i < cards.length; i++) {
      const c = cards[i];
      c.updatePosition(targetPos.x, targetPos.y + CARD_COL_MARGIN * i);
      this.moveCardSpritePositionTweens(c, c.pos);
    }
  }

  // DeckLeft to Tableau
  async onDeckLeftToTableau(card: CardSprite) {
    let col = this.checkPositionInWhichTableauCol({
      x: card.x + CARD_WIDTH / 2,
      y: card.y + CARD_HEIGHT / 2,
    });

    // ================== replay ==================
    if (isReplaying()) {
      col = currentAction().target!.col;
    }
    // ============================================

    if (col === -1) {
      this.moveAllCardsToTheirPositions();
      return;
    } else {
      const targetPos = this.calculateTableauTargetPosition(col);
      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;
      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.DECK_LEFT_TO_TABLEAU,
        card: { ...card.info },
        target: { area: AreaEnum.Tableau, col: col },
      });

      if (actionRes.valid) {
        card.updatePosition(targetPos.x, targetPos.y);
        this.moveCardSpritePositionTweens(card, card.pos);
        this.deckLeftCardSpriteMoveToTableau(col);
        // update game store
        gameStore.setState({
          game: actionRes.game,
        });
        this.updateTableauCards();
        this.reOrgDeckLeftCards();
      } else {
        this.moveAllCardsToTheirPositions();
      }
    }
  }

  // foundation to tableau
  async onFoundationToTableau(card: CardSprite) {
    const cardInfo = card.info;
    const col = this.checkPositionInWhichTableauCol({
      x: card.x + CARD_WIDTH / 2,
      y: card.y + CARD_HEIGHT / 2,
    });

    if (col === -1) {
      this.moveAllCardsToTheirPositions();
      return;
    } else {
      const targetPos = this.calculateTableauTargetPosition(col);
      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;
      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.F_TO_T,
        card: { ...cardInfo },
        target: { area: AreaEnum.Tableau, col: col },
      });

      if (actionRes.valid) {
        card.updatePosition(targetPos.x, targetPos.y);
        this.moveCardSpritePositionTweens(card, card.pos);
        this.foundationCardSpriteMoveToTableau(cardInfo, col);
        // update game store
        gameStore.setState({
          game: actionRes.game,
        });
        this.updateTableauCards();
        this.updateFoundationCardSprite();
      } else {
        this.moveAllCardsToTheirPositions();
      }
    }
  }

  // T to T
  async onTtoT(card: CardSprite) {
    const cardInfo = card.info;

    let col = this.checkPositionInWhichTableauCol({
      x: card.x + CARD_WIDTH / 2,
      y: card.y + CARD_HEIGHT / 2,
    });

    if (isReplaying()) {
      col = currentAction().target!.col;
    }

    if (col === -1) {
      this.moveAllCardsToTheirPositions();
      return;
    } else {
      // if the card is dropped to the same column
      if (col === cardInfo.col) {
        this.moveAllCardsToTheirPositions();
        return;
      }

      const targetPos = this.calculateTableauTargetPosition(col);

      const game = gameStore.getState().game;
      const gameId = gameStore.getState().gameId;
      if (!game || !gameId) return;

      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.T_TO_T,
        card: { ...cardInfo },
        target: { area: AreaEnum.Tableau, col: col },
      });

      if (actionRes.valid) {
        this.moveTableauCardsToTableauTargetPosition(card, targetPos);
        this.tableauCardSpritesMovement(card, col);
        // update game store
        gameStore.setState({
          game: actionRes.game,
        });
        this.updateTableauCards();
      } else {
        this.moveAllCardsToTheirPositions();
      }
    }
  }

  moveAllCardsToTheirPositions() {
    this.tableauCardSprites.forEach((col) => {
      col.forEach((card) => {
        if (card.x !== card.pos.x || card.y !== card.pos.y) {
          card.x = card.pos.x;
          card.y = card.pos.y;
        }
      });
    });

    this.deckCardSprites[0].forEach((card) => {
      if (card.x !== card.pos.x || card.y !== card.pos.y) {
        card.x = card.pos.x;
        card.y = card.pos.y;
      }
    });

    this.foundationCardSprites.forEach((col) => {
      col.forEach((card) => {
        if (card.x !== card.pos.x || card.y !== card.pos.y) {
          card.x = card.pos.x;
          card.y = card.pos.y;
        }
      });
    });

    interactionStore.setState({ canInteract: true });
  }

  tableauCardSpritesMovement(card: CardSprite, targetCol: number) {
    const cardInfo = card.info;
    // remove the cards from the old tableau
    const cards = this.tableauCardSprites[cardInfo.col].splice(cardInfo.row);
    // add the cards to the new tableau
    this.tableauCardSprites[targetCol].push(...cards);
    // update card info
    cards.forEach((c, index) => {
      c.info.col = targetCol;
      c.info.row =
        this.tableauCardSprites[targetCol].length - cards.length + index;
    });
  }

  deckLeftCardSpriteMoveToTableau(targetCol: number) {
    const card = this.deckCardSprites[0].pop();
    if (!card) return;
    this.tableauCardSprites[targetCol].push(card);
  }

  deckLeftCardSpriteMoveToFoundation(targetCol: number) {
    const card = this.deckCardSprites[0].pop();
    if (!card) return;
    this.foundationCardSprites[targetCol].push(card);
  }

  tableauCardSpriteMoveToFoundation(info: TVCard, targetCol: number) {
    const card = this.tableauCardSprites[info.col].pop();
    if (!card) return;
    this.foundationCardSprites[targetCol].push(card);
  }

  foundationCardSpriteMoveToTableau(info: TVCard, targetCol: number) {
    const card = this.foundationCardSprites[info.col].pop();
    if (!card) return;
    this.tableauCardSprites[targetCol].push(card);
  }

  updateTableauCards() {
    const game = gameStore.getState().game;
    if (!game) return;
    const tableau = game.cards.tableau;
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < tableau[i].length; j++) {
        const card = tableau[i][j];
        if (!card) continue;

        const sprite = this.tableauCardSprites[i][j];
        this.updateCardSpriteInfoNTextureNSizeNBringToTop(card, sprite);
      }
    }
  }

  updateFoundationCardSprite() {
    const game = gameStore.getState().game;
    if (!game) return;
    const foundation = game.cards.foundation;
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < foundation[i].length; j++) {
        const card = foundation[i][j];
        if (!card) continue;

        const sprite = this.foundationCardSprites[i][j];
        this.updateCardSpriteInfoNTextureNSizeNBringToTop(card, sprite);
      }
    }
  }

  deckRightBaseClick() {
    this.deckRightBase?.on('pointerdown', async () => {
      if (!isInteractive()) return;
      const gameId = gameStore.getState().gameId;
      if (!gameId) return;
      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.DECK_BASE_CLICK,
      });
      if (actionRes.valid) {
        gameStore.setState({
          game: actionRes.game,
        });
        this.moveAllDeckLeftToRight();

        // update score
        this.updateScore();
      }
    });

    // ================== replay ==================
    EventBus.on(DECK_BASE_CLICK, async () => {
      const gameId = gameStore.getState().gameId;
      if (!gameId) return;
      const actionRes = await gameActionHandler({
        gameId,
        actionType: ActionEnum.DECK_BASE_CLICK,
      });
      if (actionRes.valid) {
        gameStore.setState({
          game: actionRes.game,
        });
        this.moveAllDeckLeftToRight();

        // update score
        this.updateScore();
      }
    });
    // ============================================
  }

  moveAllDeckLeftToRight() {
    const game = gameStore.getState().game;
    if (!game) return;
    const deckRight = game.cards.deck[1];

    const toRemoveSprites = this.deckCardSprites[0].splice(0);
    for (let i = 0; i < toRemoveSprites.length; i++) {
      toRemoveSprites[i].updateCardInfo({
        ...deckRight[i],
      });
    }
    this.deckCardSprites[1].push(...toRemoveSprites.reverse());
    this.deckCardSprites[1].forEach((card, index) => {
      card.updatePosition(this.calculateColX(6, CARD_WIDTH), FOUNDATION_Y);
      card.x = this.calculateColX(6, CARD_WIDTH);
      card.y = FOUNDATION_Y;
      this.updateCardSpriteInfoNTextureNSizeNBringToTop(deckRight[index], card);
    });

    //
    interactionStore.setState({
      canInteract: true,
    });
  }

  //
  calculateCardsGap(cardDisplayWidth: number) {
    return (GAME_WIDTH - CARD_AREA_PADDING_X * 2 - cardDisplayWidth * 7) / 6;
  }

  calculateColX(col: number, cardDisplayWidth: number) {
    return (
      CARD_AREA_PADDING_X +
      (cardDisplayWidth + this.calculateCardsGap(cardDisplayWidth)) * col
    );
  }

  checkPositionInWhichTableauCol(pos: TPos): number {
    if (pos.y < TABLEAU_Y) {
      return -1;
    }

    const startX = CARD_AREA_PADDING_X;

    for (let col = 0; col < 7; col++) {
      const x =
        startX + col * CARD_WIDTH + col * this.calculateCardsGap(CARD_WIDTH);
      if (pos.x >= x && pos.x <= x + CARD_WIDTH) {
        return col;
      }
    }

    return -1;
  }

  checkDragendArea(
    pos: TPos,
  ): AreaEnum.Foundation | AreaEnum.Tableau | undefined {
    if (
      pos.y + CARD_HEIGHT / 2 < TABLEAU_Y &&
      pos.x <
        CARD_AREA_PADDING_X +
          4 * (CARD_WIDTH + this.calculateCardsGap(CARD_WIDTH))
    ) {
      return AreaEnum.Foundation;
    }

    if (pos.y + CARD_HEIGHT / 2 >= TABLEAU_Y) {
      return AreaEnum.Tableau;
    }
  }

  checkDragendAction(card: CardSprite, endPos: TPos): ActionEnum | undefined {
    const fromArea = card.info.area;
    const toArea = this.checkDragendArea(endPos);

    if (fromArea === AreaEnum.Tableau && toArea === AreaEnum.Tableau) {
      return ActionEnum.T_TO_T;
    }

    if (fromArea === AreaEnum.DeckLeft && toArea === AreaEnum.Tableau) {
      return ActionEnum.DECK_LEFT_TO_TABLEAU;
    }

    if (fromArea === AreaEnum.Foundation && toArea === AreaEnum.Tableau) {
      return ActionEnum.F_TO_T;
    }

    if (fromArea === AreaEnum.DeckLeft && toArea === AreaEnum.Foundation) {
      return ActionEnum.DECK_LEFT_TO_FOUNDATION;
    }

    if (fromArea === AreaEnum.Tableau && toArea === AreaEnum.Foundation) {
      const cards = this.getAllCardsNeedToMove(card.info.col, card.info.row);
      if (cards.length === 1) {
        return ActionEnum.T_TO_F;
      }
    }
  }

  getTargetFoundationIndex(card: TVCard) {
    const foundation = gameStore.getState().game?.cards.foundation;
    if (!foundation) return -1;

    // all foundation is empty
    if (foundation.every((f: TVCard[]) => f.length === 0)) {
      return 0;
    }

    // existing foundation has the same type and value
    for (let i = 0; i < foundation.length; i++) {
      const topCard = foundation[i][foundation[i].length - 1];
      if (!topCard) {
        continue;
      }
      if (topCard.type === card.type) {
        return i;
      }
    }

    // first empty foundation
    return foundation.findIndex((f: TVCard[]) => f.length === 0);
  }

  updateCardSpriteInfoNTextureNSizeNBringToTop(
    card: TVCard,
    sprite: CardSprite,
  ) {
    // update card info
    sprite.updateCardInfo(card);
    if (!card.hide) {
      // update card texture
      sprite.setTexture(CARDS, `${card.type}-${card.value}.png`);
    } else {
      sprite.setTexture(CARDS, 'cover.png');
    }
    // update card size
    sprite.resetSize();

    this.children.bringToTop(sprite);
  }

  // ======================================== Tweens ========================================
  flipDeckCardTweens(card: CardSprite, inTimeline = false) {
    // 创建一个新的时间轴
    const flipTl = gsap.timeline();

    // 第一个tween：将displayWidth缩小到0
    flipTl.to(card, {
      displayWidth: 0,
      displayHeight: CARD_HEIGHT,
      duration: CARD_FLIP_DURATION / 1000, // 将毫秒转换为秒
      ease: 'linear',
      onComplete: () => {
        // 在第一个tween完成后设置卡片纹理
        card.setTexture(CARDS, `${card.info.type}-${card.info.value}.png`);
        card.setDisplaySize(0, CARD_HEIGHT);
      },
    });

    // 第二个tween：将displayWidth恢复到原始宽度
    flipTl.to(card, {
      displayWidth: CARD_WIDTH,
      displayHeight: CARD_HEIGHT,
      duration: CARD_FLIP_DURATION / 1000, // 将毫秒转换为秒
      ease: 'linear',
      onComplete: () => {
        if (!inTimeline) {
          interactionStore.setState({
            canInteract: true,
          });
        }
      },
    });

    return flipTl;
  }

  moveCardSpritePositionTweens(
    card: CardSprite,
    targetPos: TPos,
    inTimeLine = false,
    depth = 1,
  ) {
    // 设置深度
    card.setDepth(depth);
    this.children.bringToTop(card);

    // 禁用拖动
    card.disableInteractive();

    // 使用GSAP进行动画
    return gsap.to(card, {
      x: targetPos.x,
      y: targetPos.y,
      duration: CARD_MOVING_DURATION / 1000, // 将毫秒转换为秒
      ease: 'power1.inOut',
      onComplete: () => {
        // 动画完成后的操作
        card.setDepth(0);
        if (!inTimeLine) {
          interactionStore.setState({
            canInteract: true,
          });
        }
        card.setInteractive({ draggable: true });

        // 更新分数
        this.updateScore();
      },
    });
  }

  calculateDeckLeftPositions(): TPos[] {
    const baseX = this.calculateColX(5, CARD_WIDTH);
    const result = [];

    for (let i = 0; i < 3; i++) {
      const modOfThree = i % 3;
      const factor = Math.abs(modOfThree - 2);
      const x = baseX - factor * DECK_LEFT_OFFSET - DECK_GAP;
      const y = FOUNDATION_Y;
      result.push({ x, y });
    }

    return result;
  }

  reOrgDeckLeftCards() {
    const positions = this.calculateDeckLeftPositions();

    if (this.deckCardSprites[0].length > 3) {
      const lastThreeCardSprites = this.deckCardSprites[0].slice(-3);
      for (let i = 0; i < lastThreeCardSprites.length; i++) {
        const card = lastThreeCardSprites[i];
        const pos = positions[i];
        card.updatePosition(pos.x, pos.y);
        this.moveCardSpritePositionTweens(card, pos);
      }
    }
  }

  reOrgAreas() {
    /**
     * re-organize tableau
     */
    this.reCreateTableau();
    /**
     * re-organize deck
     */
    this.reCreateDeck();
    /**
     * re-organize foundation
     */
    this.reCreateFoundation();

    // update score
    this.updateScore();
  }

  reCreateTableau() {
    this.tableauCardSprites.forEach((col) => {
      col.forEach((card) => {
        card.destroy();
      });
    });

    const gameCards = gameStore.getState().game?.cards;
    let cards: TVCard[][] = [];
    if (gameCards) {
      cards = gameCards.tableau;
    }

    for (let i = 0; i < 7; i++) {
      this.tableauCardSprites[i] = [];
      const colCards = cards[i];
      for (let j = 0; j < colCards.length; j++) {
        const current = colCards[j];
        const createdCard = this.createTableauCardSprite(current, i, j);
        this.tableauCardSprites[i].push(createdCard);
      }
    }
  }

  reCreateDeck() {
    this.deckCardSprites.forEach((deck) => {
      deck.forEach((card) => {
        card.destroy();
      });
    });
    this.deckCardSprites = [[], []];
    this.displayDeckLeft();
    this.displayDeckRight();
  }

  reCreateFoundation() {
    this.foundationCardSprites.forEach((foundation) => {
      foundation.forEach((card) => {
        card.destroy();
      });
    });
    this.foundationCardSprites = [[], [], [], []];
    // add foundation cards
    const gameCards = gameStore.getState().game?.cards;
    const foundationCards = gameCards?.foundation;
    if (!foundationCards) return;

    for (let i = 0; i < 4; i++) {
      this.foundationCardSprites[i] = [];
      const colCards = foundationCards[i];
      for (let j = 0; j < colCards.length; j++) {
        const current = colCards[j];
        const createdCard = new CardSprite(
          this,
          this.calculateColX(i, CARD_WIDTH),
          FOUNDATION_Y,
          CARDS,
          `${current.type}-${current.value}.png`,
          current,
        );
        this.foundationCardSprites[i].push(createdCard);
        createdCard.updatePosition(
          this.calculateColX(i, createdCard.displayWidth),
          FOUNDATION_Y,
        );
      }
    }
  }

  cardYoYo(card: CardSprite) {
    const duration = 0.05; // GSAP 的持续时间单位是秒
    const targetAngle = 12;

    if (card.originX !== 0) {
      return;
    }

    // 设置原点和位置
    card.setOrigin(0.5, 0);
    card.setPosition(card.x + CARD_WIDTH / 2, card.y);

    // 创建 GSAP 时间轴
    const timeline = gsap.timeline({
      onComplete: () => {
        // 动画完成后的操作
        card.angle = 0;
        card.setOrigin(0, 0);
        card.setPosition(card.x - CARD_WIDTH / 2, card.y);
        interactionStore.setState({
          canInteract: true,
        });
      },
    });

    // 添加摇摆动画步骤
    timeline
      .to(card, {
        angle: targetAngle,
        duration: duration,
        ease: 'linear',
        yoyo: true,
        repeat: 1,
      }) // 向右摆动然后回来
      .to(card, {
        angle: -targetAngle,
        duration: duration,
        ease: 'linear',
        yoyo: true,
        repeat: 1,
      }); // 向左摆动然后回来

    // 时间轴会自动播放，无需显式调用 play()
  }

  foundationPlaceholdersTweens() {
    const borders = [];
    for (const p of this.foundationPlaceholders) {
      const border = this.add.graphics();
      border.lineStyle(3, 0xffffff, 1); // 设置边框样式
      border.strokeRoundedRect(
        p.x + 8,
        p.y + 4.5,
        CARD_WIDTH - 16,
        CARD_HEIGHT - 12,
        4,
      );

      borders.push(border); // 将边框添加到数组中

      // 使用 GSAP 动画来改变边框的透明度以模拟流光效果
      gsap.fromTo(
        border,
        { alpha: 0 }, // 起始状态
        {
          alpha: 1, // 结束状态
          duration: 0.4, // 将毫秒转换为秒
          ease: (t) => {
            return (
              Phaser.Math.Easing.Quadratic.InOut(t) * (1 - t) +
              Phaser.Math.Easing.Expo.Out(t) * t
            );
          },
          onComplete: () => {
            border.alpha = 0; // 动画完成后将透明度设置为0
          },
        },
      );
    }
  }

  getCardByInfo(cardInfo: TVCard) {
    const area = cardInfo.area;
    let card: CardSprite | null = null;
    if (area === AreaEnum.Tableau) {
      card = this.tableauCardSprites[cardInfo.col][cardInfo.row];
    }

    if (area === AreaEnum.DeckLeft) {
      card = this.deckCardSprites[0][cardInfo.row];
    }

    if (area === AreaEnum.Foundation) {
      card = this.foundationCardSprites[cardInfo.col][cardInfo.row];
    }

    return card;
  }
}
