import { Card, Button, CardFooter, CardBody } from '@nextui-org/react';
import styles from './styles.module.scss';
import clsx from 'clsx';

export default function OrShopItemCard() {
  return (
    <Card
      isFooterBlurred
      radius="lg"
      className="border-none w-full bg-[#FAE9CC]"
    >
      <CardBody className="p-1">
        <div className="text-black rounded-lg border flex flex-col justify-center items-center overflow-hidden p-0">
          <div className="bg-[#317F7F] w-full flex justify-center items-center text-[#F4C14B] text-4xl font-semibold py-4">
            $5
          </div>
          <div className="flex flex-col justify-center items-center bg-[#255353] w-full gap-2 py-1">
            <span className="text-[#F4C14B] text-lg font-semibold leading-3">
              +$2
            </span>
            <span className="text-white font-semibold leading-3">
              BonusCash
            </span>
          </div>
        </div>
      </CardBody>
      <CardFooter className="pt-0 pb-1">
        <Button
          className={clsx(
            'text-white w-full font-semibold rounded-full text-lg',
            styles.btnFlow,
          )}
          color="success"
          radius="lg"
        >
          Deposit
        </Button>
      </CardFooter>
    </Card>
  );
}
